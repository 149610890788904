<input #fileInput (change)="fileChange($event)" style="display: none;" type="file">

<phar-editor-template [controlType]="FormElementsEnum.Image">
  <phar-question-editor-label general></phar-question-editor-label>
  <div response>
    <button (click)="fileInput.click()" mat-stroked-button>
      <mat-icon svgIcon="plus"></mat-icon>
      Upload image
    </button>
  </div>
</phar-editor-template>
