import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { setContextTitle, setThirdLevelMenuElements, updateContextTitle } from '../../store/actions/ui.actions';
import { AppState } from '../../store/models/app.state';
import { ActivatedRoute } from '@angular/router';
import { resetCurrentForm, searchFormById } from '../store/form.actions';

@Component({
  template: '<router-outlet></router-outlet>'
})
export class FormEditInContextComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  formId: number;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    this.formId = parseInt(this.route.snapshot.paramMap.get('formId'), 10);

    const projectId = this.route.snapshot.parent.parent.parent.params.projectId;

    const links = [
      {
        text: 'Properties',
        url: '/dashboard/study/edit/' + projectId + '/form/edit-context/' + this.formId + '/details'
      },
      {
        text: 'Questions',
        url: '/dashboard/study/edit/' + projectId + '/form/edit-context/' + this.formId + '/content'
      },
      {
        text: 'Export',
        url: '/dashboard/study/edit/' + projectId + '/form/edit-context/' + this.formId + '/export-form'

        // url: 'export-study'
      }
      /*{text: 'Rules', url: '/dashboard/study/edit/' + projectId + '/form/edit-context/' + this.formId + '/rules'}*/
    ];
    // this.permissionService.hasPermission('FormSettings').then(value => {
    //   if (value) {
    //     links.push({
    //       text: 'Settings',
    //       url: '/dashboard/study/edit/' + projectId + '/form/edit-context/' + this.formId + '/settings'
    //     });
    //   }
    // });


    this.store.dispatch(setThirdLevelMenuElements({ menuElements: links }));

    if (this.formId) {
      this.store.dispatch(searchFormById({ id: this.formId }));

      this.subscription.add(this.store.select(state => state.form.current.form).subscribe(form => {
        if (form.id) {
          this.store.dispatch(updateContextTitle({ title: form.name, position: 1 }));
        }
      }));
    } else {
      this.store.dispatch(setContextTitle({ title: 'New form', position: 2 }));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.store.dispatch(setThirdLevelMenuElements({ menuElements: [] }));
    this.store.dispatch(resetCurrentForm({ formType: null }));
  }

}
