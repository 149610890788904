<div class="form-page p-3 no-copy" [class.form-page_builder-mode]="builderMode">
  <div class="form-page_questions-list position-relative">
    @if (builderMode && (questionDragStart | async) && canDropHeader) {
      <div
        class="form-page_drop-zone form-page_drop-zone_header"
        [class.form-page_drop-zone--highlight]="(questionDragStart | async) && canDropHeader"
        (dragenter)="dragOverNewLine($event, 'start', 'header')"
        (dragleave)="dragOverNewLine($event, 'end', 'header')"
        (dragover)="allowDrop($event, 'header')"
        (drop)="onDropHeaderFooter($event, 'header')">
        <ng-container *ngTemplateOutlet="dropText"></ng-container>
      </div>
    }

    @if (page && page.questions) {
      @for (question of (page.questions | orderBy:'order'); track question.questionID) {
        @if (builderMode) {
          <div
            class="form-page_drop-zone"
            [class.form-page_drop-zone--highlight]="(questionDragStart | async) && canDropControl"
            (dragenter)="dragOverNewLine($event, 'start')"
            (dragleave)="dragOverNewLine($event, 'end')"
            (dragover)="allowDrop($event)"
            (drop)="onDrop($event, question)"
          >
            <ng-container *ngTemplateOutlet="dropText"></ng-container>
          </div>
        }
        @switch (question.controlType) {
          <!--          @case (FormElementsEnum.Grid) {-->
            <!--            <div class="cursor-move form-page_element">-->
            <!--              <mfm-grid-widget-view-->
            <!--                (afterDragEnd)="onDragEnd($event)"-->
            <!--                (afterDragStart)="onDragStart($event, question)"-->
            <!--                (afterRemove)="remove(question)"-->
            <!--                (afterUpdateClick)="editGridElement($event)"-->
            <!--                [builderMode]="builderMode"-->
            <!--                [gridBlock]="question"-->
            <!--                [id]="question.questionID"-->
            <!--                [isDraggable]="true"-->
            <!--              >-->
            <!--              </mfm-grid-widget-view>-->
            <!--            </div>-->
            <!--          }-->
            <!--          @case (FormElementsEnum.Script) {-->
            <!--            <div class="cursor-move form-page_element">-->
            <!--              <mfm-script-widget-view (afterDragEnd)="onDragEnd($event)"-->
            <!--                                      (afterDragStart)="onDragStart($event, question)"-->
            <!--                                      (afterRemove)="remove(question)"-->
            <!--                                      (afterUpdateClick)="editScriptElement($event)"-->
            <!--                                      [builderMode]="builderMode"-->
            <!--                                      [id]="question.questionID"-->
            <!--                                      [isDraggable]="true"-->
            <!--                                      [scriptBlock]="question">-->
            <!--              </mfm-script-widget-view>-->
            <!--            </div>-->
            <!--          }-->
            <!--          @case (FormElementsEnum.TextBlock) {-->
            <!--            <div class="cursor-move form-page_element">-->
            <!--              <mfm-textblock-widget-view [textblock]="question"-->
            <!--                                         [builderMode]="true"-->
            <!--                                         (afterRemove)="remove(question)"-->
            <!--                                         (afterUpdateClick)="editTextblockElement($event)"-->
            <!--                                         [isDraggable]="true"-->
            <!--                                         (afterDragStart)="onDragStart($event, question)"-->
            <!--                                         (afterDragEnd)="onDragEnd($event)"-->
            <!--                                         [id]="question.questionID">-->
            <!--              </mfm-textblock-widget-view>-->
            <!--            </div>-->
            <!--          }-->

            <!--          @case (FormElementsEnum.PieChart) {-->
            <!--            <div class="cursor-move form-page_element">-->
            <!--              <mfm-piechart-widget-view-->
            <!--                (afterDragEnd)="onDragEnd($event)"-->
            <!--                (afterDragStart)="onDragStart($event, question)"-->
            <!--                (afterRemove)="remove(question)"-->
            <!--                (afterUpdateClick)="editPieChartElement($event)"-->
            <!--                [builderMode]="builderMode"-->
            <!--                [gridBlock]="question"-->
            <!--                [id]="question.questionID"-->
            <!--                [isDraggable]="true"-->
            <!--              >-->
            <!--              </mfm-piechart-widget-view>-->
            <!--            </div>-->
            <!--          };-->
          @default {

            @if ((formQuestions$ | async)[question.id]; as formQuestionData) {
              <div [ngClass]="{
                  'form-page_element__active': (question.id === (selectedQuestion$ | async).id),
                  'form-page_element__error': (question.id === (selectedQuestion$ | async).id) && (hasPendingChanges$ | async)
                }"
                   class="form-page_element">

                <phar-question-view
                  [builderMode]="builderMode"
                  [hasChanges]="hasPendingChanges$ | async"
                  [id]="question.questionID"
                  [isDraggable]="true"
                  [isCommentsVisible]="isCommentsVisible"
                  [isVerticalLayout]="isVerticalLayout"
                  [question]="formQuestionData"
                  [pharScrollTo]="question.id === (selectedQuestion$ | async).id && this.questionService.isQuestionAutoCreateMode()"
                  (afterDelete)="remove(question)"
                  (afterDragEnd)="onDragEnd($event)"
                  (afterDragStart)="onDragStart($event, question)"
                  (afterUpdate)="editElement($event)"
                  (selectEditMode)="setEditMode($event)"
                  (requestPageChange)="handleRequestPageChange($event)"
                  (requestEditQuestion)="handleRequestEditQuestion($event)"
                  (requestResetRightPanel)="requestResetRightPanel.emit($event)"
                />
              </div>

            } @else {
              <div>
                <button
                  color="warn"
                  class="h-auto"
                  mat-raised-button
                  pharTooltipPosition="above"
                  [pharTooltip]="'Remove missing question'"
                  (click)="removeElement(question, question.type)">
                  This question is removed from our database. Press here to remove it from form.
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            }
          }
        }

      }


      @if (builderMode) {

        @if (!page?.questions?.length) {
          <div class="form-page_no-data mt-3">
            <h3>
              Please drag and drop a control from the column on the left to start creating your questionnaire
            </h3>
          </div>
        }

        <div
          class="form-page_drop-zone"
          [class.form-page_drop-zone--highlight]="(questionDragStart | async) && canDropControl"
          (dragenter)="dragOverNewLine($event, 'start')"
          (dragleave)="dragOverNewLine($event, 'end')"
          (dragover)="allowDrop($event)"
          (drop)="onDrop($event)">
          <ng-container *ngTemplateOutlet="dropText"></ng-container>
        </div>

        @if ((questionDragStart | async) && canDropFooter) {
          <div
            class="form-page_drop-zone form-page_drop-zone_footer"
            [class.form-page_drop-zone--highlight]="(questionDragStart | async) && canDropFooter"
            (dragenter)="dragOverNewLine($event, 'start', 'footer')"
            (dragleave)="dragOverNewLine($event, 'end', 'footer')"
            (dragover)="allowDrop($event, 'footer')"
            (drop)="onDropHeaderFooter($event, 'footer')">
            <ng-container *ngTemplateOutlet="dropText"></ng-container>
          </div>
        }
      }

    }
  </div>
</div>

<ng-template #dropText>
  <div class="drop-text w-100 text-center text-muted mt-1">
    Drop here
  </div>
</ng-template>

