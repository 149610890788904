<div
  draggable="true"
  class="d-flex flex-column justify-content-center align-items-center table-control-widget cursor-move no-copy widget-element"
  pharTooltip="Use Question Groups to group a variety of questions that are contingent on the response of another question"
  (dragend)="onDragEnd()"
  (dragstart)="onDragStart($event)"
>
  <img class="textblock-widget-icon" src="../../../../../assets/images/icon-question-group.svg">
</div>

