import { AfterViewInit, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';
import { filter, map, shareReplay, take, takeUntil, tap } from 'rxjs/operators';

import { AppState, getState } from '../../store/models/app.state';
import {
  deleteForm,
  loadFormListByStatuses,
  loadFormListWithStudies,
  resetCurrentForm,
  updateFormList
} from '../store/form.actions';
import {
  errorPopup,
  globalLoading,
  messagePopup,
  removeContextTitle,
  setContextTitle,
  setThirdLevelMenuElements
} from '../../store/actions/ui.actions';
import { FormModel, FormStatusEnum, FormTypeEnum } from '../form.model';
import { CardColor, CardModel, CardType } from '../../shared/card/card.model';
import { CardService } from '../../shared/card/card.service';
import { ListCardView } from '../../shared/card-list-switcher/card-list-switcher.component';
import { ListViewConfigModel } from '../../shared/list-view/list-view-model';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { UtilsService } from '../../core/utils.service';
import { ListViewColumnModel } from '../../shared/list-view/list-view-column.model';
import { HeaderService } from '../../layout/phar-header/header.service';
import { ProjectModel } from '../../project/project.model';
import { EntityType } from '../../core/models/entity-type-enum';
import { FormActions } from './form-actions.enum';
import { FormService } from '../form.service';
import { BaseComponent } from '../../shared/base.class';
import { UserModel } from '../../user/user.model';
import { selectUserList } from '../../user/store/user.reducer';
import { ListFilterManager } from '../../shared/list-filter/list-filter-manager.class';
import {
  IListFilter,
  IListFilterDisplayOption,
  ListFilter,
  ListFilterDataItem
} from '../../shared/list-filter/list-filter.interface';
import { cloneDeep, uniqBy } from 'lodash-es';
import { FilterType } from '../../shared/list-filter/filter-item.interface';
import { MatDialog } from '@angular/material/dialog';
import { MobilePreviewFormQuestionsDialogComponent } from '../mobile-preview-form-questions-dialog/mobile-preview-form-questions.component';
import { ListTemplateComponent } from '../../shared/list-template/list-template.component';
import { DropdownFilter } from '../../shared/list-filter/filter-items/dropdown-filter.class';
import { loadSettings, selectLanguages } from '../../store/actions/settings.actions';
import { ILanguage } from '../../shared/models/language.interface';
import { AppConfig } from '../../core/config/app.config';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  templateUrl: './list-form.component.html',
  styleUrls: ['./list-form.component.scss'],
})
export class ListFormComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;
  @ViewChild('cardSubtitleTemplate', { static: true }) cardSubtitleTemplate: TemplateRef<any>;
  @ViewChild('versionTemplate', { static: true }) versionTemplate: TemplateRef<any>;
  @ViewChild('releaseDateTemplate', { static: true }) releaseDateTemplate: TemplateRef<any>;
  @ViewChild('updatedAtTemplate', { static: true }) updatedAtTemplate: TemplateRef<any>;
  @ViewChild('typeTemplate', { static: true }) typeTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('languageTemplate', { static: true }) languageTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('createdTemplate', { static: true }) createdTemplate: TemplateRef<any>;
  @ViewChild('createdByTemplate', { static: true }) createdByTemplate: TemplateRef<any>;
  @ViewChild('updatedByTemplate', { static: true }) updatedByTemplate: TemplateRef<any>;
  @ViewChild('studiesTemplate', { static: true }) studiesTemplate: TemplateRef<any>;
  @ViewChild('listTemplate') listTemplate: ListTemplateComponent;
  formService: FormService = inject(FormService);
  store: Store<AppState> = inject(Store);
  appConfig = inject(AppConfig);
  formTypeEnum = FormTypeEnum;
  formStatusEnum = FormStatusEnum;
  entityType = EntityType;
  list$: Observable<FormModel[]>;
  cardView$: Observable<CardModel<FormModel>[]>;
  mappedUsers$: Observable<{
    id: string | number,
    label: string
  }[]> = this.store.select(selectUserList).pipe(
    map((users: UserModel[]) => {
      return users.map((user: UserModel) => {
        return { id: user.userId, label: user.userName };
      })
    })
  );
  users$: Observable<UserModel[]>;
  listView$: Observable<FormModel[]>;
  view: ListCardView = ListCardView.List;
  searchText: Observable<string>;
  header: Observable<boolean>;
  sorting: Observable<string>;
  inProjectContext = false;
  isReleasedFormsOnly = false;
  isAddButtonVisible = false;
  userId: number;
  menuOpened = false;
  overMenu = false;
  menuTrigger: MatMenuTrigger;
  noFilteredResultsMsg = 'No results found matching the selected criteria.'
  noItemsMsg = 'Please select Add above to start creating a questionnaire or CRF.';
  formTypes$ = of([
    { id: FormTypeEnum.DataCapture, label: 'ePRO' },
    { id: FormTypeEnum.Questionnaire, label: 'eClinRO' },
    // { id: FormTypeEnum.EObsRO, label: 'eObsRO' },
    { id: FormTypeEnum.InformedConsent, label: 'Informed Consent' },
  ]);
  studies$: Observable<{ id: number, label: string }[]> = this.formService.getProjectsShorts().pipe(
    shareReplay(1),
    map((list: Partial<ProjectModel>[]) => {
      return list.map((item) => ({ id: item.id, label: item.name }))
    })
  );
  languages$: Observable<ILanguage[]> = this.store.select(selectLanguages).pipe(
    map((languages) => languages.filter((lang) => this.appConfig.config.allowedLanguageCodes.includes(lang.code))),
  );
  languageFilterOptions$: Observable<ListFilterDataItem[]> = this.languages$.pipe(
    map(languages => languages.map((l => ({ id: l.id, label: l.name })))),
  );

  formStatuses$ = of([
    { id: FormStatusEnum.Draft, label: 'Draft' },
    { id: FormStatusEnum.PendingReview, label: 'Pending review' },
    { id: FormStatusEnum.UnderReview, label: 'Under review' },
    // { id: FormStatusEnum.Amended, label: 'Amended' }, Hide for now, currently when the form is amended its back to draft state
    { id: FormStatusEnum.Rejected, label: 'Rejected' },
  ]);

  isArchivedFilter: IListFilterDisplayOption = {
    field: 'isArchived',
    label: 'Active/Archived',
    title: 'Active/Archived',
    type: FilterType.Dropdown,
    options: {
      multiple: true,
      value: [false],
      data: of([{ id: false, label: 'Active' }, { id: true, label: 'Archived' }])
    }
  };
  config: ListViewConfigModel = {
    columns: [
      {
        field: 'name',
        title: 'Form name',
        size: '2fr',
        show: true,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
      },
      {
        field: 'type',
        title: 'Form type',
        size: '1fr',
        show: true,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
        filter: {
          field: 'type',
          title: 'Form type',
          type: FilterType.Dropdown,
          label: 'Form type',
          options: {
            data: this.formService.getFormTypes(),
            multiple: true
          }
        }
      },
      {
        field: 'language',
        title: 'Language',
        size: '1fr',
        show: true,
        order: true,
        hasTemplate: true,
        selectable: true,
        template: null,
        filter: {
          field: 'languageId',
          title: 'Language',
          label: 'Language',
          type: FilterType.Dropdown,
          options: {
            data: this.languageFilterOptions$,
            multiple: true,
          },
        },
      },
      {
        field: 'createdAt',
        title: 'Date created',
        size: '1fr',
        show: true,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
        filter: {
          field: 'createdAt',
          title: 'Date Created',
          type: FilterType.DateRange,
          label: 'Created at date range',
        }
      },
      {
        field: 'userIdCreated',
        title: 'Created By',
        size: '1fr',
        show: false,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
        filter: {
          field: 'userIdCreated',
          label: 'Created by',
          title: 'Created by',
          type: FilterType.AutoCompleter,
          options: {
            autocomplete: true,
            multiple: true,
            data: this.mappedUsers$
          }
        },
      },
      {
        field: 'updatedAt',
        title: 'Last modified',
        size: '1fr',
        show: true,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
        filter: {
          field: 'updatedAt',
          title: 'Last modified',
          type: FilterType.DateRange,
          label: 'Last modified at date range',
        }
      },
      {
        field: 'userIdUpdated',
        title: 'Last modified by',
        size: '1fr',
        show: false,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
        filter: {
          field: 'userIdUpdated',
          title: 'Last edited by',
          label: 'Last edited by',
          type: FilterType.AutoCompleter,
          options: {
            autocomplete: true,
            multiple: true,
            data: this.mappedUsers$
          }
        },
      },
      {
        field: 'releaseDate',
        title: 'Released',
        size: '1fr',
        show: true,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
        filter: {
          field: 'releaseDate',
          title: 'Release date',
          label: 'Release date',
          type: FilterType.DateRange,
        },
      },
      {
        field: 'projects',
        title: 'Related studies',
        size: '1fr',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
        filter: {
          field: 'projects',
          title: 'Related studies',
          label: 'Related studies',
          type: FilterType.AutoCompleter,
          options: {
            autocomplete: true,
            multiple: true,
            data: this.studies$,
            filterFn: (selectedValues: { name: string, id: number }[], valueItem: FormModel) => {
              return selectedValues.some(selectedValue =>
                !!valueItem.projects && valueItem.projects.some(project => project.id === selectedValue.id)
              );
            }
          }
        },
      },
      {
        field: 'version',
        title: 'Version',
        size: '1fr',
        show: true,
        hasTemplate: true,
        template: null,
        selectable: true,
        filter: {
          field: 'version',
          title: 'Version',
          type: FilterType.String,
          label: 'Version',
        }
      },
      {
        field: 'formStatus',
        title: 'Status',
        size: '1fr',
        show: true,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
        filter: {
          field: 'formStatus',
          title: 'Status',
          type: FilterType.Dropdown,
          label: 'Form Status',
          options: {
            data: this.formStatuses$,
            multiple: true
          }
        }
      },
      {
        field: 'actions',
        title: 'Actions',
        size: '50px',
        show: true,
        hasTemplate: true,
        template: null
      },
    ],
  };

  WORKSPACE_OBSOLETE_COLUMNS = ['releaseDate', 'projects'];
  LIBRARY_OBSOLETE_COLUMNS = ['updatedAt', 'formStatus'];
  urlToSkipResetContextTitle = '/dashboard/study/edit';
  lisFilterManager = new ListFilterManager();
  filterableFields: IListFilterDisplayOption[] = [];

  protected readonly FormActions = FormActions;
  protected readonly ListCardView = ListCardView;

  constructor(
    private activatedRoute: ActivatedRoute,
    protected cardService: CardService,
    private confirmDialog: PharConfirmDialogService,
    private headerService: HeaderService,
    private router: Router,
    private utilsService: UtilsService,
    private readonly dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!(window.location.href.includes(this.urlToSkipResetContextTitle))) {
      this.store.dispatch(removeContextTitle({ position: 1 }));
    } else {
      this.setProjectTitle();
    }

    this.store.dispatch(setThirdLevelMenuElements({ menuElements: [] }));
    this.store.dispatch(resetCurrentForm({ formType: null }));
    this.store.dispatch(loadSettings());
    this.setObservables();
    this.initComponentData();
    this.loadFormList();

    this.userId = getState(this.store).user.profile.userId;

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(updateFormList({ formList: [] }));
  }


  ngAfterViewInit(): void {
    const templates = {
      name: this.nameTemplate,
      version: this.versionTemplate,
      type: this.typeTemplate,
      releaseDate: this.releaseDateTemplate,
      updatedAt: this.updatedAtTemplate,
      formStatus: this.statusTemplate,
      actions: this.actionsTemplate,
      createdAt: this.createdTemplate,
      userIdCreated: this.createdByTemplate,
      userIdUpdated: this.updatedByTemplate,
      projects: this.studiesTemplate,
      language: this.languageTemplate,
    };
    this.config.columns = this.utilsService.setColumnTemplate<ListViewColumnModel>(this.config.columns, templates, 'field');
  }


  changeView($event: ListCardView): void {
    this.view = $event;
  }

  actionHandler($event: { eventName: FormActions, dataItem: FormModel }): void {
    switch ($event.eventName) {
      case FormActions.Delete:
        this.deleteCard($event.dataItem);
        break;
      case FormActions.Edit:
        this.clickHandler($event);
        break;
      case FormActions.Preview:
        this.clickHandler($event);
        break;
      case FormActions.Duplicate:
        this.duplicateHandler($event);
        break;
      case FormActions.MobilePreview:
        this.mobileFormPreview($event.dataItem);
        break;
      case FormActions.Amend:
        this.amendForm($event.dataItem);
        break;
      case FormActions.Archive:
        this.archiveForm($event.dataItem);
        break;
    }
  }

  handleFilterChange(data: { filter: IListFilter, mainFilter: boolean }): void {
    this.lisFilterManager.createFilter(data);
  }

  handleFilterUpdate(data: { action: 'update' | 'delete', filter: ListFilter | ListFilter[] }): void {
    if (data.action === 'update') {
      this.lisFilterManager.updateFilter(data.filter);
    } else {
      this.lisFilterManager.removeFilter(data.filter);
    }
  }

  clickHandler($event: { dataItem: FormModel }): void {
    this.router.navigate(['../edit', $event.dataItem.id], { relativeTo: this.activatedRoute });
  }

  duplicateHandler({ dataItem }: { dataItem: FormModel }): void {
    const isLibraryModule = dataItem.formStatus === FormStatusEnum.Released;

    this.confirmDialog.openConfirmDialog('Do you want to duplicate ' + dataItem.name + '?').pipe(
      take(1),
      filter((isConfirmed) => isConfirmed),
      switchMap(() => this.formService.duplicateForm(dataItem.id)),
      tap(() => {
        this.store.dispatch(messagePopup({ message: 'Form duplicated successfully' }));

        if (!isLibraryModule) {
          this.loadFormList();
        }
      }),
      switchMap((form) => {
        const libraryFormMessage = 'A duplicate of this form has been created in the workspace. ';
        const confirmMessage = `${isLibraryModule ? libraryFormMessage : ''}Do you want to continue editing this form?`;

        return this.confirmDialog.openConfirmDialog(confirmMessage)
          .pipe(
            filter((isConfirmed) => isConfirmed),
            map(() => form)
          );
      }),
    ).subscribe({
      next: (duplicateForm) => {
        if (isLibraryModule) {
          this.router.navigate(['../workspace', 'edit', duplicateForm.id], { relativeTo: this.activatedRoute.parent });
        } else {
          this.router.navigate(['../edit', duplicateForm.id], { relativeTo: this.activatedRoute });
        }
      },
      error: () => {
        errorPopup({ error: 'There is a problem with duplicate this form' })
      }
    });
  }

  deleteCard(form: FormModel): void {
    if (form.projects && form.projects.length) {
      this.confirmDialog.openConfirmDialog('Form is used in a study and cannot be deleted', '', 'Ok', '').pipe(
        take(1)
      ).subscribe();
      return;
    }
    this.confirmDialog.openConfirmDialog('Do you want to remove ' + form.name + '?')
      .pipe(take(1)).subscribe(result => {
      if (result) {
        this.store.dispatch(deleteForm({ id: form.id }))
      }
    });
  }

  goToCreateNew(): void {
    this.router.navigate(['../new'], { relativeTo: this.activatedRoute });
  }

  columnsSelectionChange(columns: ListViewColumnModel[]): void {
    this.config.columns = columns;
    this.setupFilterableFields(columns);
    this.handleFilterUpdate({ action: 'delete', filter: this.lisFilterManager.filters });
  }


  enter(trigger: MatMenuTrigger): void {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }

    this.menuTrigger = trigger;
    this.menuTrigger.openMenu();
    this.menuOpened = true;
  }

  leave(trigger: MatMenuTrigger, leaveMenu = false): void {
    this.overMenu = !leaveMenu;
    setTimeout(() => {
      if (this.menuOpened && this.overMenu) {
        return;
      }
      trigger.closeMenu();
      this.menuOpened = false;
      this.overMenu = false;
    }, 100)

  }

  private archiveForm(form: FormModel): void {
    this.formService.archiveForm(form.id)
      .pipe(take(1))
      .subscribe({
        next: (isArchived) => {
          if (!isArchived) {
            this.store.dispatch(messagePopup({ message: 'Form is used in a study and cannot be archived.' }));
            return;
          }

          this.store.dispatch(messagePopup({ message: 'Form archived successfully.' }));
          this.loadFormList();
        },
        error: () => {
          this.store.dispatch(errorPopup({ error: 'There is a problem with archiving.' }));
        },
      });
  }

  private mobileFormPreview(form: FormModel): void {
    this.dialog.open(MobilePreviewFormQuestionsDialogComponent, {
      data: {
        form,
        populateCurrentForm: true,
      }
    });
  }

  private amendForm(form: FormModel): void {
    let projectNames = uniqBy(form.projects, 'id').map((project) => project.name);
    let projectsText = '';
    if (projectNames.length > 3) {
      projectsText = `<b>${projectNames.slice(0, 3).join(', ')}</b> and (<b>${projectNames.length - 3}</b>) more.<br>`
    } else {
      projectsText = `<b>${projectNames.join(', ')}</b>.<br>`;
    }

    const title = 'Do you want to amend ' + form.name + '?';
    const description = form.projects && form.projects.length ? 'Selected questionnaire is used in the following studies ' +
      projectsText + 'Are you sure you want to amend this questionnaire?' :
      '';
    this.confirmDialog.openConfirmDialog(description, title).pipe(
      take(1),
      filter(result => !!result),
      switchMap(() => {
        return this.formService.amendForm(form.id);
      }),
      switchMap((form) => {
        return this.confirmDialog.openConfirmDialog('Do you want to continue editing this form?')
          .pipe(
            filter((isConfirmed) => isConfirmed),
            map(() => form)
          );
      }),
    ).subscribe({
      next: (form: FormModel) => {
        this.router.navigate(['../../workspace/edit', form.id], { relativeTo: this.activatedRoute });
      }
    })

  }

  private setProjectTitle() {
    // Handle the context menu case when we are returning from survey/project form.
    const project = this.store.select(state => state.project.current.project);
    project.pipe(
      takeUntil(this.destroy$),
      tap((project: ProjectModel) => {
        if (project.name) {
          this.store.dispatch(setContextTitle({ title: project.name, position: 1 }));
        }
      })
    ).subscribe();
  }

  private loadFormList(): void {
    this.store.dispatch(globalLoading(true));
    // if (this.inProjectContext) {
    //   this.store.select(state => state.project.current.project.id).pipe
    //   (take(2)
    //   ).subscribe(id => {
    //     if (id) {
    //       const ids = getState(this.store).project.current.project.forms;
    //       this.store.dispatch(loadProjectContextFormList({ ids }));
    //     }
    //   });

    if (this.isReleasedFormsOnly) {
      // LIBRARY
      this.store.dispatch(loadFormListWithStudies());// @TODO
    } else {
      //WORSKPACE
      this.store.dispatch(loadFormListByStatuses({
        statuses: [
          FormStatusEnum.Draft,
          FormStatusEnum.PendingReview,
          FormStatusEnum.UnderReview,
          FormStatusEnum.Rejected,
          FormStatusEnum.Amended,
        ],
      }));
    }
  }

  private filterData(forms: FormModel[]) {
    return this.lisFilterManager.filters$.pipe(
      map((filters: ListFilter[]) => {
        let dataToFilter = cloneDeep(forms);
        if (!filters.length) {
          // if there is no filters explicitly filter by isArchived false
          return this.filterByIsArchived(dataToFilter);
        }
        if (!filters.some(filter => filter.field === this.isArchivedFilter.field)) {
          // if isArchived filter is not presented, filter by it explicitly
          dataToFilter = this.filterByIsArchived(dataToFilter);
        }
        return this.lisFilterManager.filter(dataToFilter);
      })
    )
  }

  private filterByIsArchived(data: FormModel[]): FormModel[] {
    return new DropdownFilter(this.isArchivedFilter.field,
      this.isArchivedFilter.options.value,
      this.isArchivedFilter.label,
      this.isArchivedFilter.options).filter(data);
  }

  private setObservables(): void {
    this.list$ = this.store.select(state => state.form.list).pipe(
      map((formList) => {
        return formList.filter((item: FormModel) => {
          return this.activatedRoute.snapshot.data.types.includes(item.type) &&
          this.isReleasedFormsOnly ? item.formStatus === FormStatusEnum.Released : true // an additional filter for released forms
        });
      })
    );

    this.cardView$ = this.list$.pipe(
      switchMap((cards) => (this.filterData(cards))),
      map((list) => {
        return list.map((form: FormModel) => {
          const cardColor: CardColor | undefined = (() => {
            switch (form.type) {
              case FormTypeEnum.Questionnaire:
                return CardColor.Teal;
              case FormTypeEnum.DataCapture:
                return CardColor.Orange;
              case FormTypeEnum.EObsRO:
                return CardColor.Teal;
              case FormTypeEnum.InformedConsent:
                return CardColor.Orange;
            }
          })();


          return {
            original: form,
            card: {
              name: form.name,
              type: CardType.Form,
              subtitleTemplate: this.cardSubtitleTemplate,
              color: cardColor,
              isLocked: form.isLocked,
              status: form.formStatus,
              settings: this.cardService.mapCardSettings(form?.settings?.cardSettings)
            }
          };
        });
      }),
    );
    this.listView$ = this.list$.pipe(
      map((form) => (form.map((f) => ({ ...f, original: { ...f } })))),
      switchMap((list: FormModel[]) => (this.filterData(list))
      ),
    );


    this.searchText = this.store.select(state => state.ui.listFilter);
    this.header = this.store.select(state => state.ui.header);
    this.sorting = this.store.select(state => state.ui.sorting);
    this.users$ = this.store.select(selectUserList).pipe(shareReplay(1));

  }

  private initComponentData(): void {
    const { isAddButtonVisible, isContext, isReleasedOnly, types } = this.activatedRoute.snapshot.data;
    this.isAddButtonVisible = !!isAddButtonVisible;

    this.inProjectContext = isContext;
    this.isReleasedFormsOnly = isReleasedOnly;
    if (this.isReleasedFormsOnly) {
      this.arrangeLibraryColumns();

    } else {

      const hasReport = types.includes(FormTypeEnum.Report);
      const hasQuestionnaire = types.includes(FormTypeEnum.Questionnaire);
      const hasDataCapture = types.includes(FormTypeEnum.DataCapture);

      switch (true) {
        case hasReport:
          break;
        case hasQuestionnaire && hasDataCapture:
          this.arrangeWorkspaceColumns();
          break;
        // case hasQuestionnaire:
        //   // TODO:Changed Surveys to Questionnaires
        //   this.headerService.setHeaderText('Questionnaires');
        //   break;
        // case hasDataCapture:
        //   // TODO:Changed Surveys to Questionnaires
        //   this.headerService.setHeaderText('Forms');
        //   break;
        default:
          break;
      }
    }

    this.setupFilterableFields(this.config.columns);


    // this is not used yet
    // const { type } = this.activatedRoute.snapshot.queryParams;
    //
    // if (type) {
    //
    //   switch (type) {
    //     case 'survey':
    //       // TODO: Changed Surveys to Questinnaires
    //       this.store.dispatch(setContextTitle({ title: 'Surveys', position: 1 }));
    //       break;
    //     case 'reports':
    //       this.store.dispatch(setContextTitle({ title: 'Reports', position: 1 }));
    //       break;
    //     case 'data-capture':
    //       this.store.dispatch(setContextTitle({ title: 'Forms', position: 1 }));
    //       break;
    //   }
    // }

  }

  private arrangeLibraryColumns(): void {
    this.headerService.setHeaderText('Library');
    this.config.columns = this.config.columns
      .filter(c => !this.LIBRARY_OBSOLETE_COLUMNS.includes(c.field))
      .map(col => {
        switch (col.field) {
          case 'createdAt':
            return { ...col, show: false }
          case 'version':
            return { ...col, show: false }
          default:
            return col;
        }

      })
  }

  private arrangeWorkspaceColumns(): void {
    this.headerService.setHeaderText('Workspace');
    this.config.columns = this.config.columns.filter(c => !this.WORKSPACE_OBSOLETE_COLUMNS.includes(c.field));
  }

  private setupFilterableFields(columns: ListViewColumnModel[]): void {
    this.filterableFields = [
      ...columns.filter(c => c.filter && c.show).map((col: ListViewColumnModel) => (col.filter)),
      this.isArchivedFilter,
    ];
  }

  protected readonly FormStatusEnum = FormStatusEnum;
}
