import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { DataFieldBindingService } from '../bind-data-field-on-label-change/data-field-binding.service';
import { Actions, ofType } from '@ngrx/effects';
import { controlValueChanged } from '../../store/question.actions';
import { AppState, getState } from '../../../store/models/app.state';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { updateControlField, updateControlSettingsField } from '../../../store/actions/control.actions';
import { DropdownModel } from '../../../shared/models/dropdown-model';
import { CurrentControlValidationService } from '../../../shared/services/current-control-validation.service';
import { CONTROL_LABELS_MAX_LENGTH, INPUT_DEBOUNCE_TIME } from '../../../core/config/app.constants';
import { FormElementsEnum } from "../../../form/form-elements.enum";
import { ValidationsService } from '../../../core/helpers/validations.service';
import { QuestionEditorBaseComponent } from '../question-editor-base/question-editor-base.component';

@Component({
  selector: "phar-general-boolean-editor",
  templateUrl: "general-boolean-editor.component.html",
  styleUrls: ["general-boolean-editor.component.scss"],
})
export class GeneralBooleanEditorComponent
  extends QuestionEditorBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() parentDataField = "";
  @Input() bindType: string;

  form: UntypedFormGroup;

  orientationDropdownOptions: DropdownModel[] = [
    {
      label: 'Horizontal',
      value: true
    },
    {
      label: 'Vertical',
      value: false
    }
  ]
  readonly labelMaxLength = CONTROL_LABELS_MAX_LENGTH;

  constructor(
    private actions$: Actions,
    private formBuilder: UntypedFormBuilder,
    private dataFieldBindingService: DataFieldBindingService,
    protected store: Store<AppState>,
    private currentControlValidationService: CurrentControlValidationService,
    private validationsService: ValidationsService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildForm();
    this.populateValuesFromControl();
    this.updateStateOnValueChange();
    this.setFormValidObservable();

    this.currentControlValidationService.markFormAsTouched$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.form.markAllAsTouched();
      });
  }

  ngAfterViewInit(): void {
    this.actions$
      .pipe(
        ofType<ReturnType<typeof controlValueChanged>>(controlValueChanged),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        if (data.shouldUpdateDataField) {
          this.dataFieldBindingService.bindDataFieldToTheControl(data.control);
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next(null);
    this.destroy$.complete();
    this.currentControlValidationService.resetFormValidObservables();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      labelFalse: ['NO', [this.validationsService.requiredWithStripHtmlValidator, Validators.min(1), Validators.max(this.labelMaxLength)]],
      labelTrue: ['YES', [this.validationsService.requiredWithStripHtmlValidator, Validators.min(1), Validators.max(this.labelMaxLength)]],
      horizontal: [false],
    }, {
      validators: this.labelsValidator
    });
  }

  private labelsValidator(c: AbstractControl): ValidationErrors {
    const err = { notUnique: true };
    const { labelFalse, labelTrue } = c.value;

    if (!labelFalse || !labelTrue) {
      return null;
    }

    return labelFalse.toLowerCase().trim() === labelTrue.toLowerCase().trim() ? err : null;


  }

  private setFormValidObservable(): void {
    const isFormValid$ = this.form.statusChanges.pipe(
      startWith(EMPTY),
      map(() => this.form.valid),
    );

    this.currentControlValidationService.setFormValidObservable(isFormValid$);
  }

  populateValuesFromControl() {
    const control = getState(this.store).control.current.control;
    if (control && control.controlID && control.values.length > 0) {
      this.form.get('labelFalse').patchValue(control.values[0]?.label, { emitEvent: false });
      // this.form.get('valueFalse').patchValue(control.values[0]?.value, { emitEvent: false });
      this.form.get('labelTrue').patchValue(control.values[1]?.label, { emitEvent: false });
      // this.form.get('valueTrue').patchValue(control.values[1]?.value, { emitEvent: false });
      this.form.get('horizontal').patchValue(control.settings.horizontal, { emitEvent: false });
    }
  }

  private updateStateOnValueChange(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form
        .get(key)
        .valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(INPUT_DEBOUNCE_TIME),
        takeUntil(this.destroy$),
      )
        .subscribe((value) => {
          if (key === "horizontal") {
            this.store.dispatch(updateControlSettingsField(key, value));
          } else {
            const controlValues = [
              {
                label: this.form.controls.labelFalse.value,
                value: false,
                // value: this.form.controls.valueFalse.value
              },
              {
                label: this.form.controls.labelTrue.value,
                value: true,
                // value: this.form.controls.valueTrue.value
              },
            ];

            this.store.dispatch(updateControlField({ field: "values", value: controlValues }));
          }
        });
    });
  }

  protected readonly FormElementsEnum = FormElementsEnum;
}
