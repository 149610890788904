<phar-editor-template [controlType]="FormElementsEnum.MultiSelect">
  <phar-question-editor-label general></phar-question-editor-label>
  <phar-question-editor-values
    response
    [dataFieldSelector]="true"
    [maxValuesOptions]="20"
    [minValuesOptions]="2"
    [parentDataField]="parentDataField"
    [showValueTooltip]="true">
  </phar-question-editor-values>
</phar-editor-template>

