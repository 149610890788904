import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ValidationsService {

  minMaxValidator(form: AbstractControl): ValidationErrors | null {
    const min = form.get('min').value;
    const max = form.get('max').value;
    return min >= max ? { minMax: true } : null;
  }

  requiredWithStripHtmlValidator(control: AbstractControl): ValidationErrors | null {
    const trimmedValue = control.value.replace(/<[^>]*>|&nbsp;/g, '').trim();
    const isValid = trimmedValue !== '';
    return isValid ? null : { required: true };
  }

  uniqueLabelsValidator(parseFn = (v) => v.filter(x => !!x)): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const labels = parseFn(control.value);
      if (!labels.length) {
        return null;
      }
      return labels.length === new Set(labels).size ? null : { uniqueLabels: true };
    }
  }

}
