<!-- Pending changes start -->
@if (hasPendingChanges$ | async) {
  <div class="pending-changes">
    <div class="me-3 d-flex justify-content-center align-items-center">
      <mat-icon class="me-3" id="pending-changes-icon">warning</mat-icon>
      <strong id="pending-changes-title">There are pending changes.</strong>
    </div>
  </div>
}

<!-- Pending changes end -->
<div
  #formContent
  [class.form-content--no-left-bar]="hideLeftAside"
  [class.form-content--no-right-bar]="hideRightAside"
  [class.form-content_content-fullwidth]="isContentFullWidth"
  [class.form-content_right-bar-fullwidth]="isRightBarFullWidth"
  [class.hide-buttons-spacer]="(hasPendingChanges$ | async)"
  class="form-content"
>
  <aside class="form-content_leftbar">
    @if ((currentForm$ | async)?.type !== formTypes.Report) {
      <div class="form-content_leftbar_content ps-3">
        <!--<div class=" d-flex justify-content-center align-items-center p-3">
          <button mat-button
                  class="left-switch-button cursor-pointer"
                  [ngClass]="{'active-switch-button': questionsSelectedIndex === 0}"
                  (click)="questionsSelectedIndex = 0">Controls
          </button>
          <button mat-button
                  class="right-switch-button cursor-pointer"
                  [ngClass]="{'active-switch-button': questionsSelectedIndex === 1}"
                  (click)="questionsSelectedIndex = 1">Search
          </button>
        </div>-->
        <mat-tab-group class="form-content_questions-libraries" [selectedIndex]="questionsSelectedIndex">

          <mat-tab label="Controls">
            <!-- <mfm-question-library *ngIf="currentFormData.id" [targetId]="currentFormData.id" [draggable]="true"
                                  [type]="'form'" [showDelete]="true"
                                  (afterDelete)="afterDeleteQuestion($event)"
                                  (afterUpdate)="afterUpdateQuestion($event)"></mfm-question-library> -->
            @if (currentFormData.type !== formTypes.DataCapture) {
              <h6 class="cursor-pointer" (click)="toggleWidgetsPanel()">
                Elements
                @if (widgetsIsOpen) {
                  <mat-icon class="float-right cursor-pointer">keyboard_arrow_down
                  </mat-icon>
                } @else {
                  <mat-icon class="float-right cursor-pointer">keyboard_arrow_right
                  </mat-icon>
                }
              </h6>

              <div class="widgets-container_content_leftbar_list clearfix"
                   [@toggleSidebarSection]="widgetsIsOpen"
                   (mousedown)="checkPendingChangesOnClick()"
              >
                @if (currentFormData.id) {
                  @if (currentFormData.type !== formTypes.InformedConsent) {
                    <phar-question-group-widget/>
                  } @else {
                    <phar-header-widget class="px-1"></phar-header-widget>
                    <phar-footer-widget class="px-1"></phar-footer-widget>
                  }
                }
              </div>
            }


            <h6 (click)="toggleQuestionsPanel()" class="cursor-pointer">
              Controls
              @if (questionsIsOpen === 'open') {
                <mat-icon class="float-right cursor-pointer">keyboard_arrow_down
                </mat-icon>
              } @else {
                <mat-icon class="float-right cursor-pointer">keyboard_arrow_right
                </mat-icon>
              }


            </h6>

            <div class="widgets-container_content_leftbar_list clearfix"
                 (mousedown)="checkPendingChangesOnClick()"
                 [@toggleSidebarSection]="questionsIsOpen"
            >
              @if (currentFormData.id) {
                @if (currentFormData.type !== formTypes.InformedConsent) {
                  <phar-visual-analog-scale-widget></phar-visual-analog-scale-widget>
                  <phar-numeric-rating-scale-widget></phar-numeric-rating-scale-widget>
                  <phar-multi-select-widget></phar-multi-select-widget>
                  <phar-multi-select-quantity-widget></phar-multi-select-quantity-widget>
                  <phar-verbal-rating-scale-widget></phar-verbal-rating-scale-widget>
                  <phar-single-select-widget></phar-single-select-widget>
                  <phar-general-boolean-widget></phar-general-boolean-widget>
                  <phar-text-block-widget></phar-text-block-widget>
                  <phar-text-input-widget></phar-text-input-widget>
                  <phar-number-input-widget></phar-number-input-widget>
                  <phar-date-picker-widget></phar-date-picker-widget>
                  <phar-time-picker-widget></phar-time-picker-widget>
                  <phar-image-widget></phar-image-widget>
                } @else {
                  <span>ICF controls</span>
                }
              }
            </div>
          </mat-tab>
          <mat-tab label="Search">
            <!-- <mfm-question-library *ngIf="currentFormData.datasetId" [targetId]="currentFormData.datasetId"
                                  [draggable]="true" [type]="'dataset'"
                                  [showDelete]="true"
                                  (afterUpdate)="afterUpdateQuestion($event)"></mfm-question-library> -->
          </mat-tab>
        </mat-tab-group>
      </div>

    }


    @if (!isRightBarFullWidth && !isContentFullWidth) {
      <button class="form-content_aside-button"
              mat-icon-button
              pharTooltipPosition="above"
              [pharTooltip]=" (hideLeftAside ? 'Show' : 'Hide') + ' left bar'"
              [ngClass]="(hideLeftAside ? 'show' : 'hide')"
              (click)="hideLeftAside = !hideLeftAside;">
        @if (hideLeftAside) {
          <mat-icon svgIcon="arrow-right"></mat-icon>
        } @else {
          <mat-icon svgIcon="arrow-left"></mat-icon>
        }
      </button>
    }

  </aside>

  <div #formContentBody class="form-content_body">
    <mat-toolbar class="form-content_page-header d-flex justify-content-center position-sticky">
      <!--          <h5 class="form-content_page-header_title">-->
      <!--            {{ currentFormData.body.pages[pageEvent.pageIndex]?.title }}-->
      <!--          </h5>-->
      <div class="form-content_page-header_count">
        Page {{ pageEvent.pageIndex + 1 }} of {{ currentFormData.body.pages.length }}
      </div>
      <mat-paginator #paginator
                     (page)="onPageChange($event)"
                     [hidePageSize]="true"
                     [length]="currentFormData.body.pages ? currentFormData.body.pages.length : 1"
                     [pageIndex]="pageEvent.pageIndex"
                     [pageSize]="1">
      </mat-paginator>
      @if (!currentFormData.isLocked && !currentFormData?.isArchived) {
        <button class="form-content_page-header_button" mat-icon-button pharTooltipPosition="above"
                [matMenuTriggerFor]="addPageMenu" [pharTooltip]="'Add page'">
          <mat-icon svgIcon="plus"></mat-icon>
          <mat-menu #addPageMenu="matMenu" [overlapTrigger]="false">
            <button (click)="addPage()" mat-menu-item>
              After current page
            </button>
            <button (click)="addPage(true)" mat-menu-item>
              Before current page
            </button>
          </mat-menu>
        </button>

        @if (currentFormData.body.pages.length > 1 && !currentFormData.isLocked && !currentFormData.isArchived) {
          <button
            class="form-content_page-header_button"
            mat-icon-button
            pharTooltipPosition="above"
            [pharTooltip]="'Remove page'"
            (click)="removePage()">
            <mat-icon svgIcon="trash"></mat-icon>
          </button>
        }
      }
      @if (currentFormData.type === formTypes.DataCapture) {
        <button
          (click)="mobileFormPreview(currentFormData)"
          [pharTooltip]="'Mobile preview'"
          class="form-content_page-header_button"
          mat-icon-button
          pharTooltipPosition="above">
          <mat-icon svgIcon="eye"></mat-icon>
        </button>
      }
      <!--      <button (click)="toggleContentFullWidth()"-->
      <!--              [pharTooltip]="isContentFullWidth ? 'Collapse' : 'Expand'"-->
      <!--              class="form-content_page-header_button"-->
      <!--              mat-icon-button-->
      <!--              pharTooltipPosition="above">-->
      <!--        <mat-icon svgIcon="expand-5"></mat-icon>-->
      <!--      </button>-->
    </mat-toolbar>
    @if (currentFormData.settings.showHeader) {
      <div class="form-content_body_header px-3">
        @if (editMode === FormElementsEnum.Header) {
          <div class="form-content_body_header_button-block mx-3">
            <button
              class="button-block_button"
              mat-icon-button
              pharTooltipPosition="above"
              pharNoPropagation
              [pharTooltip]="'Remove header'"
              (click)="removeHeaderFooter(FormElementsEnum.Header)"
            >
              <mat-icon class="button-block_small-icon" svgIcon="trash"></mat-icon>
            </button>
          </div>
        }

        <div
          class="form-content_element px-3 py-2"
          [class.form-content_element--selected]="editMode === FormElementsEnum.Header && !pendingChangesService.hasPendingChanges"
          [class.form-content_element--pending-changes]="editMode === FormElementsEnum.Header && pendingChangesService.hasPendingChanges"
          (click)="editHeaderFooter(FormElementsEnum.Header)">
          <div class="form-content_body_header_text">{{currentFormData.body.header.text}}</div>
        </div>
      </div>
    }

    @if (currentFormData.body.pages[pageEvent.pageIndex]) {
      <phar-form-page [builderMode]="!currentFormData.isLocked && !currentFormData.isArchived"
                      [page]="currentFormData.body.pages[pageEvent.pageIndex]"
                      [pageIndex]="pageEvent.pageIndex"
                      [form]="currentFormData"
                      (editModeChanged)="updateEditMode($event)"
                      (formUpdated)="saveForm()"
                      (afterUpdate)="updatePage($event)"
                      (requestPageChange)="handleRequestPageChange($event)"
                      (requestEditQuestion)="handleRequestEditQuestion($event)"
                      (requestResetRightPanel)="handleRequestRightPanel()"
      />
    }

    @if (currentFormData.settings.showFooter) {
      <div class="form-content_body_footer px-3 pb-3">
        @if (editMode === FormElementsEnum.Footer) {
          <div class="form-content_body_footer_button-block mx-3">
            <button
              class="button-block_button"
              mat-icon-button
              pharTooltipPosition="above"
              pharNoPropagation
              [pharTooltip]="'Remove footer'"
              (click)="removeHeaderFooter(FormElementsEnum.Footer)"
            >
              <mat-icon class="button-block_small-icon" svgIcon="trash"></mat-icon>
            </button>
          </div>
        }

        <div
          class="form-content_element px-3 py-2"
          [class.form-content_element--selected]="editMode === FormElementsEnum.Footer && !pendingChangesService.hasPendingChanges"
          [class.form-content_element--pending-changes]="editMode === FormElementsEnum.Footer && pendingChangesService.hasPendingChanges"
          (click)="editHeaderFooter(FormElementsEnum.Footer)">
          <div class="form-content_body_footer_text">{{currentFormData.body.footer.text}}</div>
        </div>
      </div>

    }
  </div>

  <aside class="form-content_rightbar widgets-container_content_rightbar">
    @if (!isContentFullWidth && !isRightBarFullWidth) {
      <div
        class="resize-handle"
        [class.resizing]="rightBarResizingEvent.isResizing"
        (mousedown)="startRightBarResizing($event)"
      ></div>

      <button class="form-content_aside-button"
              mat-icon-button
              pharTooltipPosition="above"
              [pharTooltip]=" (hideRightAside ? 'Show' : 'Hide') + ' Right bar'"
              [ngClass]="(hideRightAside ? 'show' : 'hide')"
              (click)="hideRightAside = !hideRightAside"
      >
        <!--        @if (hideRightAside) {-->
        <!--          <mat-icon svgIcon="arrow-left"></mat-icon>-->
        <!--        } @else {-->
        <!--          <mat-icon svgIcon="arrow-right"></mat-icon>-->
        <!--        }-->
        <mat-icon class="rotate">unfold_more</mat-icon>
      </button>

    }
    @if (!hideRightAside) {
      <div
        [ngClass]="{'container': isRightBarFullWidth}"
        class="widgets-container_content_rightbar_content">
        <div class="d-flex justify-content-between">
          <div class="d-flex justify-content-center p-3 flex-1">
            <!-- &lt;!&ndash;<button mat-button
                     class="left-switch-button cursor-pointer"
                     [ngClass]="{'active-switch-button': widgetEditorActive === WidgetEditorType.Pages}"
                     (click)="setActiveTabIndex(0)">Pages
             </button>&ndash;&gt;-->
            <button
              class="left-switch-button cursor-pointer"
              pharTooltip="Question properties define the question parameters"
              pharTooltipPosition="above"
              mat-button
              [ngClass]="{'active-switch-button': widgetEditorActive === WidgetEditorType.Properties}"
              (click)="setActiveTabIndex(1)"
            >
              Properties
            </button>
            <button class="right-switch-button cursor-pointer"
                    mat-button
                    pharTooltip="Rules can be used for functions such as hiding a question based on another response or setting parameters for the responses"
                    pharTooltipPosition="above"
                    [ngClass]="{'active-switch-button': widgetEditorActive === WidgetEditorType.Rules}"
                    [disabled]="!editMode || disabledRulesFormElements.includes(editMode)"
                    (click)="setActiveTabIndex(2)"
            >
              Rules ({{selectedControlRulesLength$ | async}})
            </button>
          </div>

          <!--        <button (click)="toggleRightBarFullWidth()"-->
          <!--                [pharTooltip]="isContentFullWidth ? 'Collapse' : 'Expand'"-->
          <!--                mat-icon-button-->
          <!--                pharTooltipPosition="above">-->
          <!--          <mat-icon svgIcon="expand-5"></mat-icon>-->
          <!--        </button>-->
        </div>


        <mat-tab-group [selectedIndex]="widgetEditorActive" class="form-content_questions-libraries">

          <mat-tab label="Pages">

            <div class="form-content_page-selector">
              <div class="d-flex justify-content-between">
                <h3>Pages</h3>
                @if (!currentFormData.isLocked && !currentFormData.isArchived) {
                  <mat-icon
                    (click)="addPage()"
                    class="float-right cursor-pointer">
                    add
                  </mat-icon>
                }
              </div>
              @for (page of currentFormData.body.pages; track $index) {
                <div
                  class="form-content_page-selector_element d-flex flex-column align-items-center justify-content-center"
                  [class.form-content_page-selector_element--active]="pageEvent.pageIndex === $index">
                  <div
                    class="form-content_page-selector_card form-content_page-selector_page form-content_page-selector_image"
                    (click)="pageEvent.pageIndex = $index;">
                  </div>
                  <h5 class="form-content_page-selector_title mt-2">
                    <label>{{ page.title }}</label>
                  </h5>
                </div>
              }


            </div>

          </mat-tab>


          <mat-tab label="Properties">
            <!-- <ng-container *ngIf="(selectedQuestion$ | async)">
              <mfm-question-builder-editor-title *ngIf="editMode === 'title'"></mfm-question-builder-editor-title>
            </ng-container> -->
            @if ((selectedControl$ | async); as selectedControl) {

              @switch (editMode) {
                @case (FormElementsEnum.NumericRatingScale) {
                  <phar-numeric-rating-scale-editor [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.VisualAnalogScale) {
                  <phar-visual-analog-scale-editor [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.MultiSelect) {
                  <phar-question-editor-multi-select [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.MultiSelectQuantity) {
                  <phar-question-editor-multi-select-quantity [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.VerbalRatingScale) {
                  <phar-verbal-rating-scale-editor [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.SingleSelect) {
                  <phar-single-select-editor [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.TextBlock) {
                  <phar-text-block-editor [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.Boolean) {
                  <phar-general-boolean-editor [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.TextInput) {
                  <phar-text-input-editor [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.Number) {
                  <phar-number-input-editor [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.DatePicker) {
                  <phar-date-picker-editor [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.TimePicker) {
                  <phar-time-picker-editor [bindType]="selectedControl.controlType"/>
                }
                @case (FormElementsEnum.Image) {
                  <phar-image-editor [bindType]="selectedControl.controlType"/>
                }

              }

            }
            @switch (editMode) {
              @case (FormElementsEnum.QuestionGroup) {
                <phar-question-group-editor [question]="selectedQuestionSnapshot$ | async"
                                            [formType]="(currentForm$ | async).type"/>
              }
              @case (FormElementsEnum.Header) {
                <phar-header-editor></phar-header-editor>
              }
              @case (FormElementsEnum.Footer) {
                <phar-footer-editor></phar-footer-editor>
              }
            }
            @if (!(selectedControl$ | async)?.controlID && !editMode) {
              <h6 class="text-center">Select a control to enter the properties.</h6>
            }

          </mat-tab>

          <mat-tab label="Rules">
            @if (widgetEditorActive === WidgetEditorType.Rules) {
              <phar-rules-list/>
            } @else {
              <h6 class="text-center">Select a control to enter the rules.</h6>
            }
          </mat-tab>
        </mat-tab-group>
      </div>
    }
  </aside>
</div>
