<phar-editor-template [controlType]="FormElementsEnum.VisualAnalogScale">
  <phar-question-editor-label general></phar-question-editor-label>
  <form [formGroup]="form" response>
    <div class="row gx-0 mb-3">
      <div class="d-flex flex-column col-6 pe-1">
        <mat-label class="required-field">Min</mat-label>
        <mat-form-field class="mat-input-small" subscriptSizing="dynamic">
          <input formControlName="min" matInput placeholder="Min" type="number">
          <mat-error>
            @if (form.get('min').hasError('min')) {
              Min value can't be negative.
            } @else if (form.get('min').hasError('max')) {
              Value max is 1000.
            } @else if (form.get('min').hasError('required')) {
              Min is <b>required</b>.
            }
          </mat-error>
        </mat-form-field>
      </div>

      <div class="d-flex flex-column col-6 ps-1">
        <mat-label class="required-field">Max</mat-label>
        <mat-form-field class="mat-input-small" subscriptSizing="dynamic">
          <input formControlName="max" matInput placeholder="Max" type="number">
          <mat-error>
            @if (form.get('max').hasError('min')) {
              Min value can't be negative.
            } @else if (form.get('max').hasError('max')) {
              Value max is 1000.
            } @else if (form.get('max').hasError('required')) {
              Min is <b>required</b>.
            }
          </mat-error>
        </mat-form-field>
      </div>
    </div>


    @if (form.controls.min.valid && form.controls.max.valid && form.hasError('minMax')) {
      <mat-error>
        <b>Min</b> must be less than <b>Max</b>
      </mat-error>
    }
    <div formArrayName="labels">
      <div class="d-flex flex-column">
        <mat-label>Label - Min (optional)</mat-label>

        <mat-form-field class="remove-will-change">
          <phar-html-input
            [formControlName]="0"
            placeholder="Label"
            [maxlength]="labelMaxLength"
            [pharMaxLengthTooltip]="labelMaxLength"
          >
          </phar-html-input>
          <!--          <input
                      matInput
                      placeholder="Label"
                      [maxlength]="labelMaxLength"
                      [pharMaxLengthTooltip]="labelMaxLength"
                      [formControlName]="0"
                      pharInputTrim>-->
          <mat-hint class="hint-focus-visible">Entered characters {{ form.get('labels').get('0').value.length }}
            /{{ labelMaxLength }}
          </mat-hint>

          @if (form.get('labels').get('0').hasError('required')) {
            <mat-error>
              Label is <strong>required</strong>
            </mat-error>
          }


          @if (form.get('labels').get('0').hasError('maxlength')) {

            <mat-error>
              Entered characters {{ form.get('labels').get('0').value.length }}/{{ labelMaxLength }}
            </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="d-flex flex-column">
        <mat-label>Label - Max (optional)</mat-label>

        <mat-form-field class="remove-will-change">
          <phar-html-input
            [formControlName]="1"
            placeholder="Label"
            [maxlength]="labelMaxLength"
            [pharMaxLengthTooltip]="labelMaxLength"
          >
          </phar-html-input>
          <!--   <input
               matInput
               placeholder="Label"
               [maxlength]="labelMaxLength"
               [pharMaxLengthTooltip]="labelMaxLength"
               [formControlName]="1"
               pharInputTrim/>-->
            <mat-hint class="hint-focus-visible">Entered characters {{ form.get('labels').get('1').value.length }}/255
            </mat-hint>

          @if (form.get('labels').get('1').hasError('required')) {
            <mat-error>
              Label is <strong>required</strong>
            </mat-error>
          }

          @if (form.get('labels').get('1').hasError('maxlength')) {
            <mat-error>
              Entered characters {{ form.get('labels').get('1').value.length }}/{{ labelMaxLength }}
            </mat-error>
          }

        </mat-form-field>
      </div>
      @if (form.controls.labels.hasError('uniqueLabels')) {
        <mat-error>
          Labels must be <b>unique.</b>
        </mat-error>
      }
    </div>

  </form>
</phar-editor-template>
