<phar-list-template #listTemplate>
  <div listHeader>
    @if (isAddButtonVisible) {
      <div class="d-flex flex-row justify-content-between mt-2 px-4">
        <button id="add-form" class="ms-auto" (click)="goToCreateNew()" mat-stroked-button>
          <mat-icon svgIcon="plus"></mat-icon>
          Add
        </button>
      </div>
    }

    <div class="d-flex flex-row justify-content-between mt-4 mb-2 ms-3">

      <phar-list-filter [searchField]="'name'"
                        [activeFilters]="lisFilterManager.filters$ | async"
                        [displayedFilterOptions]="filterableFields"
                        [resultCounter]="(listView$ | async).length"
                        (filterAdd)="handleFilterChange($event)"
                        (filterUpdate)="handleFilterUpdate($event)"
      />
      <div class="d-flex flex-row justify-content-between ps-3 pe-2 ms-auto">
        <phar-card-list-switcher class="ms-auto" [ngClass]="{'me-3': view !== ListCardView.List}" [view]="view"
                                 (afterViewSelected)="changeView($event)"/>
        <phar-list-column-selection
          class="ms-1"
          [class.d-none]="view !== ListCardView.List"
          [columns]="config.columns"
          (columnsSelectionChange)="columnsSelectionChange($event)"/>
      </div>
    </div>
  </div>
  <div class="list-page-cards" listBody [class.list-page-cards--no-header]="inProjectContext"
       [class.scroll-holder--inSubContext]="inProjectContext"
       [class.scroll-holder--no-header]="!!!(header | async)"
  >


    @if (view === ListCardView.Card) {
      <phar-cards-list
        [cardsType]="'form'"
        [cards]="(cardView$ | async) | orderBy: (sorting | async)"
        [moreOptions]="cardActionsTemplate"
        [noDataMessage]="(lisFilterManager.filters$ | async).length ? noFilteredResultsMsg : noItemsMsg"
        uniqueIdentifier="'id'"/>
    } @else {
      <div class="px-3" [ngClass]="{'vw-100-scroll__': !inProjectContext}">
        <phar-list-view
          [parentHeight]="listTemplate.listBody.nativeElement.clientHeight"
          [itemHeight]="60"
          [config]="config"
          [dataSource]="listView$ | async | orderBy: (sorting | async)"
          [noDataMessage]="(lisFilterManager.filters$ | async).length ? noFilteredResultsMsg : noItemsMsg"
          (afterActionClicked)="actionHandler($event)"
          (afterClick)="clickHandler($event)"/>
      </div>

    }


  </div>


</phar-list-template>


<ng-template #nameTemplate let-element>

  <span
    #formName
    class="text-truncate"
    [pharTooltip]="element.name"
    [pharTooltipDisabled]="formName.scrollWidth <= formName.clientWidth">
    {{ element.name }}
  </span>

  @if (element.formDuplicateId) {
    <mat-icon
      class="mx-1 list-form-name-icon"
      svgIcon="duplicate">
    </mat-icon>
  }
</ng-template>

<ng-template #cardSubtitleTemplate let-element>

  <div class="mt-2 text-nowrap">
    <div>
      Created: {{ element.createdAt | date: 'd MMM y' }} -
      @if (element.userIdCreated | pharUserById: (users$ | async); as user) {
        <span [pharTooltip]="user"
              [pharTooltipDisabled]="user === 'N/A'">{{ user |  shorten: 17: '...' }}
      </span>
      }
    </div>
    @if (element.formStatus === FormStatusEnum.Released) {
      <div>
        Released: {{ element.releaseDate | date: 'd MMM y' }}
      </div>
    } @else {
      <div>
        Modified: {{ element.updatedAt | date: 'd MMM y' }} -
        @if (element.userIdUpdated | pharUserById: (users$ | async); as user) {
          <span [pharTooltip]="user"
                [pharTooltipDisabled]="user === 'N/A'">{{ user  | shorten: 17: '...' }}
        </span>
        }
      </div>
    }
  </div>

</ng-template>

<ng-template #releaseDateTemplate let-element>
  {{ element.releaseDate | date: 'd MMM y' }}
</ng-template>

<ng-template #versionTemplate let-element>
  <!-- | number:'1.1-10'-->
  {{ element.version }}
</ng-template>

<ng-template #updatedAtTemplate let-element>
  {{ element.updatedAt | date: 'd MMM y' }}
</ng-template>

<ng-template #typeTemplate let-element>
  <span class="list-type-black">
      {{ element.type | pharFormType }}
  </span>

</ng-template>

<ng-template #statusTemplate let-element>
  <phar-status-badge [status]="element.formStatus" [type]="entityType.Form"/>
</ng-template>

<ng-template #languageTemplate let-element>
  {{ element.languageId | pharLanguage: (languages$ | async) }}
</ng-template>


<ng-template #createdTemplate let-element>
  {{ element.createdAt | date: 'd MMM y' }}
</ng-template>

<ng-template #createdByTemplate let-element>
  @if (element.userIdCreated | pharUserById: (users$ | async); as user) {
    <span [pharTooltip]="user"
          [pharTooltipDisabled]="user === 'N/A'">{{ user |  shorten: 17: '...' }}
      </span>
  }
</ng-template>

<ng-template #updatedByTemplate let-element>
  @if (element.userIdUpdated | pharUserById: (users$ | async); as user) {
    <span [pharTooltip]="user"
          [pharTooltipDisabled]="user === 'N/A'">{{ user |  shorten: 17: '...' }}
      </span>
  }
</ng-template>

<ng-template #cardActionsTemplate let-card>
  <ng-template
    [ngTemplateOutlet]="actionsTemplate"
    [ngTemplateOutletContext]="{$implicit: card.original}">
  </ng-template>
</ng-template>

<ng-template #studiesTemplate let-element>
  <span class="fw-normal">

  @if (element.projects && element.projects.length) {
    <div [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
         (mouseenter)="enter(menuTrigger)" (mouseleave)="leave(menuTrigger)">
          @if ((element.projects | pluck: 'name' | unique).join(', '); as projects) {
            <span class="fw-normal">
        {{ projects | shorten: 28: '...' }}
      </span>
          }
      <mat-menu [class]="'project-list-menu'" [hasBackdrop]="false" #menu="matMenu" [overlapTrigger]="false"


      >
        <div (mouseenter)="overMenu = true" (mouseleave)="leave(menuTrigger, true)">
                 @for (project of (element.projects | unique: 'id'); track project.id) {
                   <button mat-menu-item
                           routerLink="../edit/{{ project.id }}">{{ project.name }}</button>
                 }
        </div>

    </mat-menu>
    </div>


  } @else {
    -
  }
  </span>

</ng-template>

<ng-template #actionsTemplate let-element>
  <button
    class="ms-auto"
    mat-icon-button
    (click)="$event.stopImmediatePropagation()" [matMenuTriggerFor]="actionsMenu">
    <mat-icon class="more">more_vert</mat-icon>
  </button>

  <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
    @if (element.isLocked || element.isArchived) {
      <button class="button-with-icon"
              mat-menu-item
              (click)="actionHandler({ eventName: FormActions.Preview, dataItem: element })"
      >
        <mat-icon svgIcon="eye"></mat-icon>
        <span>Preview</span>
      </button>
    } @else {
      <button
        class="button-with-icon"
        mat-menu-item
        (click)="actionHandler({ eventName: FormActions.Edit, dataItem: element })"
      >
        <mat-icon svgIcon="edit"></mat-icon>
        <span>Edit</span>
      </button>
    }




    <button class="button-with-icon"
            (click)="actionHandler({ eventName: FormActions.Duplicate, dataItem: element })"
            mat-menu-item>
      <mat-icon svgIcon="duplicate"></mat-icon>
      <span>Duplicate</span>
    </button>

    @if (element.type === formTypeEnum.DataCapture) {
      <button
        (click)="actionHandler({ eventName: FormActions.MobilePreview, dataItem: element })"
        class="button-with-icon"
        mat-menu-item>
        <mat-icon svgIcon="eye"></mat-icon>
        <span>Mobile Preview</span>
      </button>
    }

    @if (!element.isArchived) {
      <button
        class="button-with-icon"
        mat-menu-item
        (click)="actionHandler({ eventName: FormActions.Archive, dataItem: element })">
        <mat-icon svgIcon="folder"></mat-icon>
        <span>Archive</span>
      </button>
    }

    @if (element.formStatus === formStatusEnum.Released) {
      <button
        class="button-with-icon"
        mat-menu-item
        (click)="actionHandler({ eventName: FormActions.Amend, dataItem: element })">
        <mat-icon svgIcon="change"></mat-icon>
        <span>Amend</span>
      </button>
    }

    <button
      class="button-with-icon"
      (click)="actionHandler({ eventName: FormActions.Delete, dataItem: element })"
      mat-menu-item>
      <mat-icon svgIcon="trash"></mat-icon>
      <span>Delete</span>
    </button>
  </mat-menu>
</ng-template>
