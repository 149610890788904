@if (question | async; as q) {
  <div class="full-width full-height">

    <mat-toolbar [ngClass]="{'pending-changes-color' : hasPendingChanges$ | async}"
                 class="question-builder_first-toolbar"
                 color="primary">
      @if ((hasPendingChanges$ | async) === false) {
        <div class="d-flex pe-3 me-3" id="nav-section">
          <div class="cursor-pointer d-flex" (click)="close()">
            <mat-icon class="align-self-center me-2">arrow_back</mat-icon>
            Back
          </div>
        </div>

      }
      <div class="d-flex align-items-center">
        Question builder
        @if (q.title) {
          <mat-icon class="me-1 ms-1">keyboard_arrow_right</mat-icon>
        }
        {{ q.title }}
      </div>
      @if (hasPendingChanges$ | async) {
        <div class="ms-auto d-flex align-items-center">
          <mat-icon class="me-3" id="pending-changes-icon">warning</mat-icon>
          There are pending changes.
          <div id="action-btns">
            <button id="discard" mat-raised-button (click)="discardChanges()">
              Discard
            </button>
            <button class="ms-2" id="save" mat-raised-button (click)="submit()">
              Save
            </button>
          </div>
        </div>

      }
      <div class="ms-auto d-flex">
        <!--            <input class="d-none" type="file" #fileSelect (change)="addFormFromAI($event)" accept=".png,.jpg"/>-->
        <!--            <mat-icon class="align-self-center me-2"-->
        <!--                      matTooltip="Scanned Document will be converted into form fields from AI">-->
        <!--              info_outlined-->
        <!--            </mat-icon>-->
        <!--            <button mat-raised-button (click)="fileSelect.click()" class="me-2">-->
        <!--              AI Scan Document-->
        <!--            </button>-->
        <!--            <div matTooltip="See extracted fields" *ngIf="uploadedImg" id="image-wrapper" class="h-100">-->
        <!--              <img src="../../../assets/images/form_recognized2.png" (click)="previewImage()" class="cursor-pointer w-100">-->
        <!--            </div>-->
        <!--                    <button mat-raised-button (click)="close()">-->
        <!--                      Cancel-->
        <!--                    </button>-->
        <!--                    <button mat-raised-button type="submit" class="ms-2 button-success" (click)="submit()">-->
        <!--                      Save-->
        <!--                    </button>-->
      </div>
    </mat-toolbar>

    <mat-toolbar
      class="question-builder_second-toolbar mat-elevation-z1 position-relative d-flex justify-content-center">
      <div class="d-flex me-auto flex-1"></div>
      <div class="question-builder_second-toolbar_tab-menu">
        <button class="mfm-tlm_link" mat-button [class.active]="selectedTab === 'content'"
                (click)="selectedTab = 'content'">
        <span class="d-flex align-items-center">
          Content
        </span>
        </button>
        <button class="mfm-tlm_link" mat-button [class.active]="selectedTab === 'rules'"
                (click)="selectedTab = 'rules'">
        <span class="d-flex align-items-center">
          Rules
        </span>
        </button>
      </div>
      <div class="d-flex justify-content-end ms-auto flex-1">


      </div>
    </mat-toolbar>
    @if (selectedTab === 'content') {
      <div class="question-builder_content">
        <aside class="question-builder_content_leftbar">
          <!--<h6 (click)="toggleElementsPanel()" class="cursor-pointer pb-4">
           Elements
           <mat-icon class="float-right" *ngIf="elementsIsOpen === 'open'">keyboard_arrow_down</mat-icon>
           <mat-icon class="float-right" *ngIf="elementsIsOpen === 'close'">keyboard_arrow_right</mat-icon>
         </h6>
         <div class="question-builder_content_leftbar_list clearfix" [@toggleSidebarSection]="elementsIsOpen">
           <mfm-repeatable-group-widget></mfm-repeatable-group-widget>
           <mfm-textblock-widget></mfm-textblock-widget>
           <mfm-table-element-widget></mfm-table-element-widget>
           <mfm-data-table-widget></mfm-data-table-widget>
           <mfm-grid-radio-widget></mfm-grid-radio-widget>
           <mfm-widget [type]="FormElementsEnum.QuestionGroup"></mfm-widget>
         </div>-->
          <h6 class="cursor-pointer pb-4" (click)="toggleControlsPanel()">
            Controls
            <mat-icon class="float-right">
              {{ controlsIsOpen === 'open' ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
            </mat-icon>
          </h6>
          <div class="question-builder_content_leftbar_list clearfix" [@toggleSidebarSection]="controlsIsOpen">
            <!--<mfm-textbox-widget></mfm-textbox-widget>
            <mfm-number-widget></mfm-number-widget>
            <mfm-number-widget [isFloat]="true"></mfm-number-widget>
            <mfm-radio-button-list-widget></mfm-radio-button-list-widget>
            <mfm-checkbox-widget></mfm-checkbox-widget>
            <mfm-dropdown-list-widget></mfm-dropdown-list-widget>
            <mfm-date-widget></mfm-date-widget>
            <mfm-time-widget></mfm-time-widget>
            <mfm-image-upload-widget></mfm-image-upload-widget>
            <mfm-file-upload-widget></mfm-file-upload-widget>
            <mfm-signature-widget></mfm-signature-widget>
            <mfm-script-widget></mfm-script-widget>
            <mfm-boxed-input-widget></mfm-boxed-input-widget>
            <mfm-radio-image-list-widget></mfm-radio-image-list-widget>
            <mfm-image-tagging-widget></mfm-image-tagging-widget>
            <mfm-slider-widget></mfm-slider-widget>
            <mfm-image-highlighting-widget></mfm-image-highlighting-widget>
            <mfm-user-select-widget></mfm-user-select-widget>
            <mfm-hidden-input-widget></mfm-hidden-input-widget>
            <mfm-widget [type]="FormElementsEnum.Text"></mfm-widget>
            <mfm-widget [type]="FormElementsEnum.VisualAnalogScale"></mfm-widget>
            <phar-verbal-rating-scale-widget></phar-verbal-rating-scale-widget>
            <phar-numeric-rating-scale-widget></phar-numeric-rating-scale-widget>
            <phar-multi-select-widget></phar-multi-select-widget>
            <phar-multi-select-quantity-widget></phar-multi-select-quantity-widget>
            <mfm-widget [type]="FormElementsEnum.Boolean"></mfm-widget>
            <phar-single-select-widget></phar-single-select-widget>-->
          </div>
        </aside>
        <div class="d-flex flex-column position-relative">
          <!--        <mfm-information-stripe *ngIf="(hasPendingChanges$ | async)" [hideSaveBtn]="true"-->
          <!--                                class="position-absolute w-100 px-5"></mfm-information-stripe>-->
          <div class="question-builder_content_body p-3">
            @if (loading$ | async) {
              <div class="global-loader d-flex justify-content-center align-items-center">
                <mat-spinner diameter="40"></mat-spinner>
              </div>
            } @else {
              <div class="mfm-paper-content p-3 mat-elevation-z2">
                <phar-question-view (selectEditMode)="setEditMode($event)" [question]="q" />
              </div>
            }
          </div>

        </div>
        <aside class="question-builder_content_rightbar">

          @if (editMode === 'title') {
            <phar-question-builder-editor-title/>
          }

          <!-- <mfm-question-builder-editor-textblock *ngIf="editMode === FormElementsEnum.TextBlock"
            [parentDataField]="parentDataField"></mfm-question-builder-editor-textblock> -->

          @switch (editMode) {
<!--            @case (FormElementsEnum.TextInput) {-->
<!--              <mfm-question-builder-editor-texbox [bindType]="(selectedControl | async).controlType"-->
<!--                                                  [parentDataField]="parentDataField"/>-->
<!--            }-->
            <!--            @case (FormElementsEnum.RadioButtons) {-->
              <!--              <mfm-question-editor-radio-button-list [bindType]="(selectedControl | async).controlType"-->
              <!--                                                     [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.CheckBox) {-->
              <!--              <mfm-question-editor-checkbox [bindType]="(selectedControl | async).controlType"-->
              <!--                                            [parentDataField]="parentDataField"/>-->
              <!--            }-->
            @case (FormElementsEnum.MultiSelect) {
              <phar-question-editor-multi-select [bindType]="(selectedControl | async).controlType"
                                                 [parentDataField]="parentDataField"/>
            }
            @case (FormElementsEnum.MultiSelectQuantity) {
              <phar-question-editor-multi-select-quantity [bindType]="(selectedControl | async).controlType"
                                                          [parentDataField]="parentDataField"/>
            }
            <!--            @case (FormElementsEnum.DropDownList) {-->
              <!--              <mfm-question-editor-dropdown [bindType]="(selectedControl | async).controlType"-->
              <!--                                            [parentDataField]="parentDataField"/>-->
              <!--            }-->
<!--            @case (FormElementsEnum.DatePicker) {-->
<!--              <mfm-question-builder-editor-date [bindType]="(selectedControl | async).controlType"-->
<!--                                                [parentDataField]="parentDataField"/>-->
<!--            }-->
<!--            @case (FormElementsEnum.TimePicker) {-->
<!--              <mfm-time-editor [bindType]="(selectedControl | async).controlType" [parentDataField]="parentDataField"/>-->
<!--            }-->
<!--            @case (FormElementsEnum.RepeatableGroup) {-->
<!--              <mfm-repeatable-group-editor [parentDataField]="parentDataField"/>-->
<!--            }-->
<!--            @case (FormElementsEnum.ImageUpload) {-->
<!--              <mfm-question-editor-image-upload [bindType]="(selectedControl | async).controlType"-->
<!--                                                [parentDataField]="parentDataField"/>-->
<!--            }-->
            <!--            @case (FormElementsEnum.FileUpload) {-->
              <!--              <mfm-question-editors-file-upload [bindType]="(selectedControl | async).controlType"-->
              <!--                                                [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.Signature) {-->
              <!--              <mfm-signature-editor-->
              <!--                [bindType]="(selectedControl | async).controlType" [parentDataField]="parentDataField"/>-->
              <!--            }-->
<!--            @case (FormElementsEnum.Number || editMode === FormElementsEnum.Float) {-->
<!--              <mfm-question-builder-editor-number-->
<!--                [bindType]="(selectedControl | async).controlType" [parentDataField]="parentDataField"/>-->
<!--            }-->
            <!--            @case (FormElementsEnum.Script) {-->
              <!--              <mfm-script-editor [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.BoxedInput) {-->
              <!--              <mfm-boxed-input-editor [bindType]="(selectedControl | async).controlType"-->
              <!--                                      [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.TableElement) {-->
              <!--              <mfm-table-element-editor [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.DataTable) {-->
              <!--              <mfm-data-table-editor [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.GridRadio) {-->
              <!--              <mfm-grid-radio-editor [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.RadioImagesList) {-->
              <!--              <mfm-radio-image-list-editor [bindType]="(selectedControl | async).controlType"-->
              <!--                                           [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.ImageTagging) {-->
              <!--              <mfm-image-tagging-editor [bindType]="(selectedControl | async).controlType"-->
              <!--                                        [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.Slider) {-->
              <!--              <mfm-slider-editor [bindType]="(selectedControl | async).controlType"-->
              <!--                                 [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.ImageHighlighting) {-->
              <!--              <mfm-image-highlighting-editor [bindType]="(selectedControl | async).controlType"-->
              <!--                                             [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.UserSelect) {-->
              <!--              <mfm-user-select-editor [bindType]="(selectedControl | async).controlType"-->
              <!--                                      [parentDataField]="parentDataField"/>-->
              <!--            }-->
              <!--            @case (FormElementsEnum.HiddenInput) {-->
              <!--              <mfm-hidden-input-editor [bindType]="(selectedControl | async).controlType"-->
              <!--                                       [parentDataField]="parentDataField"/>-->
              <!--            }-->
            @case (FormElementsEnum.NumericRatingScale) {
              <phar-numeric-rating-scale-editor
                [bindType]="(selectedControl | async).controlType" [parentDataField]="parentDataField"/>
            }
            @case (FormElementsEnum.VerbalRatingScale) {
              <phar-verbal-rating-scale-editor [bindType]="(selectedControl | async).controlType"
                                               [parentDataField]="parentDataField"/>
            }
            @case (FormElementsEnum.SingleSelect) {
              <phar-single-select-editor [bindType]="(selectedControl | async).controlType"
                                         [parentDataField]="parentDataField"/>
            }
          }


        </aside>
      </div>

    }
<!--    @if (selectedTab === 'rules') {-->
<!--      <div class="question-builder_rules">-->
<!--        <div class="p-5 container">-->
<!--          <div class="question-builder_rules_section">-->
<!--            <div class="question-builder_rules_section_title p-3 d-flex align-items-center cursor-pointer"-->
<!--                 (click)="openSection = openSection === 'visible' ? '' : 'visible'">-->
<!--              <mat-icon class="me-2">visibility</mat-icon>-->
<!--              <span>The question is visible when</span>-->
<!--              <mat-icon class="ms-2">-->
<!--                {{ openSection === 'visible' ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}-->
<!--              </mat-icon>-->
<!--            </div>-->
<!--            @if (openSection === 'visible') {-->
<!--              <div class="question-builder_rules_section_content p-3 mb-3">-->
<!--                @if (!rules.show) {-->
<!--                  <button class="rule-builder_logic_button-new button-success"-->
<!--                          [matMenuTriggerFor]="newLogicMenu"-->
<!--                          mat-fab>-->
<!--                    +-->
<!--                    <mat-menu #newLogicMenu="matMenu" [overlapTrigger]="false">-->
<!--                      <p class="rule-builder_logic_select-title">Operator:</p>-->
<!--                      <button class="rule-builder_logic_button-change-logic d-flex align-items-center"-->
<!--                              mat-menu-item-->
<!--                              (click)="addRule(ruleLogicEnum.And, 'show')"-->
<!--                      >-->
<!--                        <strong>&& AND</strong>-->
<!--                      </button>-->
<!--                      <button class="rule-builder_logic_button-change-logic d-flex align-items-center"-->
<!--                              mat-menu-item-->
<!--                              (click)="addRule(ruleLogicEnum.Or, 'show')"-->
<!--                      >-->
<!--                        <strong>|| OR</strong>-->
<!--                      </button>-->
<!--                    </mat-menu>-->

<!--                  </button>-->

<!--                } @else {-->
<!--&lt;!&ndash;                  <mfm-rule-builder-logic-view #ruleBuilderLogicViewComponent&ndash;&gt;-->
<!--&lt;!&ndash;                                               [dataSet]="currentDataset.data" [isChild]="true"&ndash;&gt;-->
<!--&lt;!&ndash;                                               [rule]="rules.show"&ndash;&gt;-->
<!--&lt;!&ndash;                                               (formValidityUpdate)="updateRuleValidity($event)"&ndash;&gt;-->
<!--&lt;!&ndash;                                               (afterUpdate)="updateRule($event, 'show')"/>&ndash;&gt;-->
<!--                }-->
<!--              </div>-->

<!--            }-->
<!--          </div>-->
<!--          <div class="question-builder_rules_section">-->
<!--            <div class="question-builder_rules_section_title p-3 d-flex align-items-center cursor-pointer"-->
<!--                 (click)="openSection = openSection === 'hidden' ? '' : 'hidden'">-->
<!--              <mat-icon class="me-2">visibility_off</mat-icon>-->
<!--              <span>The question is hidden when</span>-->
<!--              <mat-icon class="ms-2">-->
<!--                {{ openSection === 'hidden' ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}-->
<!--              </mat-icon>-->
<!--            </div>-->
<!--            @if (openSection === 'hidden') {-->
<!--              <div class="question-builder_rules_section_content p-3 mb-3">-->
<!--                @if (!rules.hide) {-->
<!--                  <button [matMenuTriggerFor]="newLogicMenu"-->
<!--                          class="rule-builder_logic_button-new button-success"-->
<!--                          mat-fab>-->
<!--                    +-->
<!--                    <mat-menu #newLogicMenu="matMenu" [overlapTrigger]="false">-->
<!--                      <p class="rule-builder_logic_select-title">Operator:</p>-->
<!--                      <button class="rule-builder_logic_button-change-logic d-flex align-items-center"-->
<!--                              mat-menu-item (click)="addRule(ruleLogicEnum.And, 'hide')">-->
<!--                        <strong>&& AND</strong>-->
<!--                      </button>-->
<!--                      <button class="rule-builder_logic_button-change-logic d-flex align-items-center"-->
<!--                              mat-menu-item-->
<!--                              (click)="addRule(ruleLogicEnum.Or, 'hide')"-->
<!--                      >-->
<!--                        <strong>|| OR</strong>-->
<!--                      </button>-->
<!--                    </mat-menu>-->
<!--                  </button>-->
<!--                } @else {-->
<!--                  <mfm-rule-builder-logic-view #ruleBuilderLogicViewComponent-->
<!--                                               [dataSet]="currentDataset.data" [isChild]="true"-->
<!--                                               [rule]="rules.hide"-->
<!--                                               (afterUpdate)="updateRule($event, 'hide')"-->
<!--                                               (formValidityUpdate)="updateRuleValidity($event)"-->
<!--                  />-->
<!--                }-->

<!--              </div>-->
<!--            }-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    }-->

  </div>

}
