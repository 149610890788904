/* src/app/question/editors/values-editor/values-editor.component.scss */
.question-editor_values-row:nth-child(n+2) {
  margin-top: -1em;
}
.question-editor_values-row {
  margin: 15px 0;
}
.values-editor-error {
  margin-top: -5px;
}
#add-response {
  font-size: 12px;
}
/*# sourceMappingURL=values-editor.component.css.map */
