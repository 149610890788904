<div [formGroup]="parentFormGroup">
  <div class="row gx-0 mt-3">
    <div class="col-12">
      <mat-label class="required-field">Rule title</mat-label>
      <div class="d-flex align-items-center">
        <mat-form-field class="w-100">
                      <textarea
                        class="text-area-scrollable"
                        formControlName="title"
                        cdkAutosizeMaxRows="20"
                        cdkAutosizeMinRows="1"
                        matInput
                        cdkTextareaAutosize
                        pharInputTrim
                        placeholder="Enter a rule title"
                        [maxLength]="500"
                        [pharMaxLengthTooltip]="500"
                      >
                      </textarea>
          <mat-error>
            Title is <strong>required</strong>
          </mat-error>
        </mat-form-field>

      </div>
    </div>
  </div>
  <div class="row gx-0">
    <div class="col-12">
      <mat-label class="required-field">Response</mat-label>
      <mat-form-field class="w-100">
        <mat-select formControlName="response" placeholder="Response is">
          @for (condition of conditions; track $index) {
            <mat-option [value]="condition.value">
              <span>{{ condition.text }}</span>
            </mat-option>
          }
        </mat-select>

        <mat-error>
          Type is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row gx-0 mt-3">
    <div class="col-12">
      <mat-label class="required-field">Value</mat-label>
      <div class="d-flex align-items-center">
        @switch (controlType) {
          @case ('string') {
            <ng-template [ngTemplateOutlet]="stringTemplate"
                         [ngTemplateOutletContext]="{$implicit: parentFormGroup}"></ng-template>
          }
          @case ('boolean') {
            <ng-template [ngTemplateOutlet]="stringTemplate"
                         [ngTemplateOutletContext]="{$implicit: parentFormGroup}"></ng-template>
          }
          @case ('number') {
            <mat-form-field class="w-100">
              @if (shouldShowTwoValueInputs$ | async) {
                <phar-number-range formControlName="value"></phar-number-range>
              } @else {
                <input matInput
                       type="number"
                       formControlName="value"
                       pharInputTrim
                       placeholder="Enter a value"
                />
              }
              <mat-error>
                Value is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          }
          @case ('date') {
            @if (shouldShowTwoValueInputs$ | async) {
              <mat-form-field class="range-picker w-100">
                <phar-date-picker-value startLabel="Start date"
                                        endLabel="End date"
                                        formControlName="value"
                                        [picker]="picker"/>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error>
                  Value is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            } @else {
              <mat-form-field class="w-100">
                <phar-datepicker-input [picker]="picker" formControlName="value"
                                       placeholder="Select date">
                </phar-datepicker-input>
                <mat-datepicker-toggle class="datepicker-toggle" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error>
                  Value is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            }
          }
          @case ('time') {
            @if (shouldShowTwoValueInputs$ | async) {
              <mat-form-field class="w-100">
                <phar-time-range-picker formControlName="value"></phar-time-range-picker>
                <mat-icon matSuffix>access_time</mat-icon>
                <mat-error>
                  Value is <strong>required</strong>
                </mat-error>
              </mat-form-field>

            } @else {
              <mat-form-field class="w-100">
                <phar-timepicker-field class="time-picker-field w-100" [withPeriod]="false" [showSeconds]="false"
                                       formControlName="value"/>
                <mat-icon matSuffix>access_time</mat-icon>
                <mat-error>
                  Value is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            }
          }
        }

      </div>
    </div>
  </div>

  <div class="row gx-0 mt-3">
    <div class="col-12">
      <mat-label class="required-field">Error message</mat-label>
      <div class="d-flex align-items-center">
        <mat-form-field class="w-100">
                      <textarea
                        class="text-area-scrollable"
                        formControlName="errorMessage"
                        cdkAutosizeMaxRows="20"
                        cdkAutosizeMinRows="1"
                        matInput
                        cdkTextareaAutosize
                        pharInputTrim
                        placeholder="Enter an error message"
                        [maxLength]="500"
                        [pharMaxLengthTooltip]="500"
                      >
                      </textarea>
          <mat-error>
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field>

      </div>
    </div>
  </div>
</div>

<ng-template #stringTemplate let-parentFormGroup>
  <ng-container [formGroup]="parentFormGroup">
    <mat-form-field class="w-100">
                      <textarea
                        class="text-area-scrollable"
                        formControlName="value"
                        cdkAutosizeMaxRows="20"
                        cdkAutosizeMinRows="1"
                        matInput
                        cdkTextareaAutosize
                        pharInputTrim
                        placeholder="Enter a value"
                        [maxLength]="500"
                        [pharMaxLengthTooltip]="500"
                      >
                      </textarea>
      <mat-error>
        Value is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

</ng-template>
