export enum RuleLogicEnum {
  'And' = 'and',
  'Or' = 'or',
  'Equal' = 'eq',
  'NotEqual' = 'neq',
  'NotBetween' = 'nbtw',
  'GreaterThanOrEqual' = 'gte',
  'GreaterThan' = 'gt',
  'LessThanOrEqualTo' = 'lte',
  'LessThan' = 'lt',
  'Contains' = 'contains',
  'DoesNotContain' = 'doesnotcontain',
  'IsEmpty' = 'isempty',
  'IsNotEmpty' = 'isnotempty',
  'Range' = 'range'
}
