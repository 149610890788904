import { Component, Inject, OnInit } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/models/app.state';
import { Observable } from 'rxjs';
import { selectCurrentFormStateForm } from '../../form/store/form.state';
import { map } from 'rxjs/operators';
import { IDuplicateDialogData } from './duplicate-dialog-data.interface';

export enum CopyTargetEnum {
  CurrentPage,
  AnotherPage
}

@Component({
  selector: 'phar-duplicate-dialog',
  templateUrl: 'duplicate-dialog.component.html',
  styleUrl: 'duplicate-dialog.component.scss',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatIcon,
    MatIconButton,
    NgIf,
    SharedModule,
    NgForOf,
    AsyncPipe
  ]
})

export class DuplicateDialogComponent implements OnInit {
  copyTargetFormCtrl: FormControl<number> = new FormControl<number>(CopyTargetEnum.CurrentPage);
  selectedPageFormCtrl: FormControl<number> = new FormControl<number | null>(null);
  targetOptions: { id: number, label: string }[] = [
    { id: CopyTargetEnum.CurrentPage, label: 'Current Page' },
    { id: CopyTargetEnum.AnotherPage, label: 'Another Page' },
  ]
  CopyTargetEnum = CopyTargetEnum;

  pages$: Observable<{ id: number, title: string }[]>;

  constructor(private store: Store<AppState>, public dialogRef: MatDialogRef<DuplicateDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IDuplicateDialogData) {
  }

  ngOnInit() {
    this.selectedPageFormCtrl.setValue(this.data.currentPageIndex);
    this.pages$ = this.store.select(selectCurrentFormStateForm).pipe(map(state => {
      return state.body.pages.map((page, index: number) => {
        return { id: index, title: page.title };
      })
    }));
    if (this.data.mode === 'move') {
      this.copyTargetFormCtrl.setValue(CopyTargetEnum.AnotherPage);
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  handleAction() {
    if (this.data.mode === 'move' && this.data.currentPageIndex === this.selectedPageFormCtrl.value) {
      this.close();
      return;
    }
    this.dialogRef.close({ pageIndex: this.selectedPageFormCtrl.value });
  }

}
