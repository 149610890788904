<button (click)="closeDialog()" class="close-icon" mat-icon-button>
  <mat-icon class="button-block_small-icon" svgIcon="close"></mat-icon>
</button>

<div class="d-flex justify-content-center mb-1">
  <button
    mat-icon-button
    [matTooltip]="isVerticalLayout ? 'Landscape' : 'Portrait'"
    (click)="toggleMobileView()">
    <mat-icon svgIcon="phone-2" [ngClass]="{ 'rotate': isVerticalLayout }"></mat-icon>
  </button>
</div>

<div class="phone" [ngClass]="{ 'phone_portrait': isVerticalLayout, 'phone_landscape': !isVerticalLayout}">
  @if (form$ | async; as form) {
    <div class="phone_content">

      <div class="phone_content_form-name">{{ form.name }}</div>

      <mat-divider></mat-divider>

      @if (form.body.pages[paginator.pageIndex]) {
        <phar-form-page
          #formPageContent
          [builderMode]="false"
          [page]="form.body.pages[paginator.pageIndex]"
          [isCommentsVisible]="false"
          [isVerticalLayout]="isVerticalLayout"
          class="phone_content_form-page" />
      }

      <mat-divider></mat-divider>

      <mat-toolbar class="bg-transparent">
        <h5>{{ form.body.pages[paginator.pageIndex]?.title }}</h5>
        <div class="ms-auto">
          Page {{ paginator.pageIndex + 1 }} of {{ paginator.length }}
        </div>
        <mat-paginator
          #paginator
          [hidePageSize]="true"
          [length]="form.body.pages ? form.body.pages.length : 1"
          [pageSize]="1"
          (page)="scrollToTop()">
          aa
        </mat-paginator>
      </mat-toolbar>
    </div>
  }
</div>
