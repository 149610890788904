import { Component, Input, OnInit } from '@angular/core';
import { FormElementsEnum } from '../../../form/form-elements.enum';
import { ElementTypePipe } from '../../../shared/element-type/element-type.pipe';
import { AppState, getState } from '../../../store/models/app.state';
import { Store } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { selectCurrentQuestionPendingChanges } from '../../store/question.state';
import { PendingChangesService } from '../../../shared/pending-changes.service';

@Component({
  selector: 'phar-editor-template',
  templateUrl: 'editor-template.component.html',
  styleUrl: 'editor-template.component.scss',
  imports: [
    ElementTypePipe,
    AsyncPipe,
    MatButton,
    MatIcon
  ],
  standalone: true
})

export class EditorTemplateComponent implements OnInit {
  @Input() firstTitle = 'General';
  @Input() secondTitle = 'Response settings';
  @Input() controlType: FormElementsEnum;
  hasPendingChanges$: Observable<boolean>;

  constructor(private store: Store<AppState>, private pendingChangesService: PendingChangesService) {
  }

  ngOnInit(): void {
    this.hasPendingChanges$ = this.store.select(selectCurrentQuestionPendingChanges);
  }

  triggerSave(): void {
    const controlID = getState(this.store).control.current.control.controlID;
    this.pendingChangesService.triggerSave(controlID);
  }

  triggerDiscard(): void {
    this.pendingChangesService.triggerDiscardChanges();
  }
}
