<div [formGroup]="form">
  <div formArrayName="values">
    <mat-label class="mb-3 inline-block required-field">Response options</mat-label>
    @for (item of form.controls['values']['controls']; let i = $index; track item) {
      <div [formGroupName]="i"
           class="question-editor_values-row d-flex justify-items-between align-items-start">

        <div class="d-flex flex-column align-items-start w-100">
          <mat-form-field class="mat-input-small w-100 remove-will-change">
            <phar-html-input
              formControlName="label"
              placeholder="Label"
              [maxlength]="labelMaxLength"
              [pharMaxLengthTooltip]="labelMaxLength"
            >
            </phar-html-input>

            <!--        <input
                      matInput
                      formControlName="label"
                      placeholder="Label"
                      [maxlength]="labelMaxLength"
                      [pharMaxLengthTooltip]="labelMaxLength"
                      pharInputTrim>-->
            <mat-hint class="hint-focus-visible">Entered characters {{ item.get('label').value.length }}
              /{{ labelMaxLength }}
            </mat-hint>



            @if (item.get('label').hasError('required')) {
              <mat-error class="values-editor-error">
                Label is <strong>required</strong>
              </mat-error>

            }

            @if (item.get('label').hasError('maxlength')) {
              <mat-error class="values-editor-error">
                Entered characters {{ item.get('label').value.length }}/{{ labelMaxLength }}
              </mat-error>
            }
          </mat-form-field>
        </div>

        <!-- <div *ngIf="showQuantity" class="d-flex flex-column col-4 pe-0">
          <mat-label [matTooltip]="'Default quantity'" matTooltipPosition="above">Def.Qty</mat-label>
          <mat-form-field>
            <input
              matInput
              formControlName="quantity"
              placeholder="0"
              type="number"
              min="0"
              [maxlength]="50"
              [pharMaxLengthTooltip]="50">
            <mat-error *ngIf="item.get('quantity').hasError('required')">
              <strong>Required</strong>
            </mat-error>
          </mat-form-field>
        </div> -->

        @if (!dataFieldSelector && showValue) {
          <div class="d-flex flex-column col-4 pe-0">
            <mat-label>Value</mat-label>
            <mat-form-field class="mat-input-small">
              <input
                [maxlength]="255"
                [pharMaxLengthTooltip]="255"
                formControlName="value"
                matInput
                pharInputTrim
                placeholder="Value">
            </mat-form-field>
          </div>
        }
        <mat-icon class="cursor-pointer editor-trash-icon"
                  svgIcon="trash"
                  matTooltip="Remove Option"
                  (click)="removeValue(i)">
        </mat-icon>
      </div>
    }


    <mat-error class="mat-error mb-3">
      @if (displayMinValuesReachedError) {
        Minimum 2 response options are allowed
      }
      @if (form.controls.values.hasError('uniqueLabels')) {
        Response options must be <b>unique</b>
      }
    </mat-error>


  </div>
  @if (form.invalid) {
    @if (form?.errors?.validValues) {
      <mat-error>{{ form?.errors?.validValues }}</mat-error>
    }

  }
  <button id="add-response" mat-stroked-button
          [disabled]="maxValuesOptions && form.controls['values']['length'] >= maxValuesOptions"
          (click)="addValue()">
    <mat-icon svgIcon="plus"></mat-icon>
    Add Response Option
  </button>

</div>
