<div class="editor-container">
  <div class="editor-content">
    @if (firstTitle) {
      <!--  <h6 class="mb-4">{{ firstTitle }}</h6>-->
      @if (controlType) {
        <div class="mb-3">Question Type: <span class="fw-bold">{{ controlType | pharElementType }}</span></div>
      }
      <ng-content select="[general]"></ng-content>
    }
    @if (secondTitle) {
      <h6 class="mb-4">{{ secondTitle }}</h6>
      <ng-content select="[response]"></ng-content>
    }
  </div>
  <div class="editor-actions d-flex align-items-center">
    <button class="me-auto btn-small" mat-raised-button [disabled]="!(hasPendingChanges$ | async)" (click)="triggerDiscard()">
      <mat-icon [svgIcon]="'close'"></mat-icon>
      <b>Discard</b>
    </button>

    <button mat-raised-button
            class="ms-auto btn-small button-success"
            (click)="triggerSave()"
            [disabled]="!(hasPendingChanges$ | async)"
    >
      <mat-icon svgIcon="check"></mat-icon>
      <b>Save</b>

    </button>
  </div>
</div>







