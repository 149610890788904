/* src/app/form/mobile-preview-form-questions-dialog/mobile-preview-form-questions.component.scss */
:host {
  display: block;
  padding: 15px 40px 40px;
  max-height: 100vh;
  max-width: 100vw;
  overflow: auto;
}
:host .close-icon {
  position: absolute;
  top: 0;
  right: 0;
}
:host .rotate {
  rotate: -90deg;
}
:host .phone {
  border: 40px solid #121212;
  border-radius: 40px;
  box-sizing: content-box;
  overflow: hidden;
}
:host .phone_portrait {
  width: 393px;
  min-width: 393px;
  height: 852px;
  border-width: 55px 7px;
}
@media (max-height: 1070px) {
  :host .phone_portrait {
    zoom: 90%;
  }
}
@media (max-height: 980px) {
  :host .phone_portrait {
    zoom: 80%;
  }
}
@media (max-height: 880px) {
  :host .phone_portrait {
    zoom: 70%;
  }
}
:host .phone_landscape {
  width: 852px;
  min-width: 852px;
  height: 393px;
  border-width: 7px 55px;
}
@media (max-width: 1320px) {
  :host .phone_landscape {
    zoom: 90%;
  }
}
@media (max-width: 1185px) {
  :host .phone_landscape {
    zoom: 80%;
  }
}
@media (max-width: 1065px) {
  :host .phone_landscape {
    zoom: 70%;
  }
}
:host .phone_content {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}
:host .phone_content_form-page {
  flex: 1;
  overflow-y: auto;
}
:host .phone_content ::ng-deep .mat-mdc-paginator-range-label {
  display: none;
}
:host .phone_content_form-name {
  font-size: 1rem;
  font-weight: 600;
  margin: 1rem 0;
  text-align: center;
}
/*# sourceMappingURL=mobile-preview-form-questions.component.css.map */
