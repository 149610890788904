<phar-editor-template [controlType]="FormElementsEnum.Number">
  <phar-question-editor-label general></phar-question-editor-label>
  <form response [formGroup]="form">
    @if (form.get('inputs')['controls'][0]; as firstInputGroup) {

      <ng-container *ngTemplateOutlet="inputEditorTemplate; context: { $implicit: firstInputGroup }"></ng-container>


      <div class="row w-100">
        <div class="col-6">
          <mat-label>Units/Descriptor</mat-label>
          <mat-form-field class="w-100 remove-will-change">
            <phar-html-input
              formControlName="descriptor"
              placeholder="Units/Descriptor"
              [maxLength]="labelMaxLength"
              [pharMaxLengthTooltip]="labelMaxLength"
            >
            </phar-html-input>

            <mat-hint class="hint-focus-visible">
              Entered characters {{ form.get('descriptor').value.length }}/{{ labelMaxLength }}
            </mat-hint>
            <!--            <input-->
            <!--              matInput-->
            <!--              type="string"-->
            <!--              formControlName="descriptor"-->
            <!--              placeholder="Units/Descriptor"-->
            <!--              [maxLength]="labelMaxLength"-->
            <!--              [pharMaxLengthTooltip]="labelMaxLength"-->
            <!--              pharInputTrim>-->

              <mat-error>
                  Entered characters {{ form.get('descriptor').value.length }}/{{ labelMaxLength }}
              </mat-error>
          </mat-form-field>
        </div>

        <div class="col-6 p-0">
          <mat-label>Additional operator</mat-label>
          <mat-form-field class="w-100">
            <mat-select formControlName="additionalOperator">
              @for (operator of additionalOperators; track $index) {
                <mat-option [value]="operator.value">
                  <span>{{ operator.label }}</span>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>


    }


    @if (form.get('inputs')['controls'][1]; as secondInputGroup) {
      <div class="row w-100">
        <div class="col-6">
          <mat-label>Operator</mat-label>
          <mat-form-field class="w-100">
            <mat-select formControlName="operator">
              @for (operator of operators; track $index) {
                <mat-option [value]="operator.value">
                  <span>{{ operator.label }}</span>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6 p-0"></div>
      </div>

      <ng-container *ngTemplateOutlet="inputEditorTemplate; context: { $implicit: secondInputGroup }"></ng-container>
    }
    @if (form.get('inputs')['length'] >= 2) {
      <button mat-stroked-button (click)="removeSecondFields()">
        <mat-icon svgIcon="trash"></mat-icon>
        Remove value
      </button>

    }
    @if (form.get('inputs')['length'] <= 1) {
      <button mat-stroked-button (click)="addSecondFields()">
        <mat-icon svgIcon="plus"></mat-icon>
        Add value
      </button>
    }

  </form>

</phar-editor-template>

<ng-template #inputEditorTemplate let-formGroup>
  <div class="row w-100" [formGroup]="formGroup">
    <div class="col-6">
      <mat-label class="required-field">Integer places</mat-label>
      <mat-form-field class="w-100">
        <input
          [min]="1"
          formControlName="numberOfIntegers"
          matInput
          placeholder="Number"
          type="number">

        @if (formGroup.get('numberOfIntegers').hasError('required')) {
          <mat-error>
            Field is <strong>required</strong>
          </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-6 p-0">
      <mat-label class="required-field">Decimal places</mat-label>
      <mat-form-field class="w-100">
        <input
          formControlName="numberOfDecimals"
          matInput
          min="0"
          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
          placeholder="Number"
          type="number">
        <mat-error>
          Field is <b>required</b>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</ng-template>
