import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, getState, selectCurrentControl } from '../../../../store/models/app.state';
import { EMPTY, Observable, of } from 'rxjs';
import { ControlModel } from '../../../control.model';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { RuleActionEnum } from '../../../../rule/rule-action.enum';
import { QuestionModel } from '../../../question.model';
import { RuleTypeEnum } from '../../../../rule/rule-type.enum';
import { updateControlField } from '../../../../store/actions/control.actions';
import { CurrentControlValidationService } from '../../../../shared/services/current-control-validation.service';
import { selectCurrentFormStateFormPopulatedQuestions } from '../../../../form/store/form.state';
import { RuleLogicEnum } from '../../../../rule/rule-logic.enum';
import { RulesType } from '../../../rules-list/rules-type.enum';
import { RuleModel } from '../../../../rule/rule.model';
import { BaseComponent } from '../../../../shared/base.class';
import { isDropdownSelectionComponent } from '../selection-types';
import { UtilsService } from '../../../../core/utils.service';
import { FormControls } from '../../../../shared/types/form-controls.type';

export interface IRule {
  title: string | null;
  type: string | null;
  action: string | null;
  controlId: string | null;
  controlResponseValueIndex: number | null;
  order: number;
  logic: string | null;
  condition: string | null;
  additionalValue: any;
}

export type RuleForm = FormControls<IRule>

interface RulesForm {
  rules: FormArray<FormGroup<RuleForm>>
}

@Component({
  selector: 'phar-conditional-rules-editor',
  templateUrl: 'conditional-rules-editor.component.html',
  styleUrls: ['conditional-rules-editor.component.scss']
})
export class ConditionalRulesEditorComponent extends BaseComponent implements OnInit, OnDestroy {
  ruleForm: FormGroup<RulesForm>;
  ruleForm$: Observable<FormGroup<RulesForm>>;
  currentControl$: Observable<ControlModel>;
  allFormControls$: Observable<ControlModel[]>;

  openCloseState: { [key: number]: boolean } = {}

  constructor(
    private store: Store<AppState>,
    private currentControlValidationService: CurrentControlValidationService,
  ) {
    super();
  }

  get rules(): FormArray {
    return this.ruleForm?.get('rules') as FormArray
  }

  ngOnInit(): void {
    this.allFormControls$ = this.store.select(selectCurrentFormStateFormPopulatedQuestions)
      .pipe(
        map((questions: Record<string, QuestionModel>) => {
          const nestedControls = Object.values(questions)
            .map((question) => question.controls as ControlModel[]);

          return nestedControls.reduce((acc, controls) => acc.concat(controls), []);
        }));

    this.currentControl$ = this.store.select(selectCurrentControl)
      .pipe(
        filter(({ controlID }) => !!controlID),
        distinctUntilChanged((prev, curr) => prev.controlID === curr.controlID),
      );

    this.ruleForm$ = this.currentControl$
      .pipe(
        switchMap((control) => {
          const form: FormGroup<RulesForm> = this.getInitialForm();
          return this.setControlRulesToTheForm(form, control);
        }),
        tap((formGroup) => this.ruleForm = formGroup),
        shareReplay(1),
      );

    this.populateRuleToTheControlState();
    this.setFormValidObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.currentControlValidationService.resetFormValidObservables();
  }

  toggleOpenCloseState(index: number): void {
    this.openCloseState[index] = !this.openCloseState[index]
  }

  addCondition(length: number, control: ControlModel): void {
    const group: FormGroup<RuleForm> = this.generateNewGroupItem({
      title: `Condition ${length + 1}`
    });
    if (!isDropdownSelectionComponent(control)) {
      group.controls.logic.addValidators(Validators.required);
      group.updateValueAndValidity();
    }

    if (!group.controls.condition.value) {
      group.controls.additionalValue.disable();
    }

    this.rules.push(group);
    this.openCloseState[this.rules.controls.length - 1] = true
  }

  removeRule(index: number): void {
    this.rules.removeAt(index);
    delete this.openCloseState[index];
  }


  private getInitialForm(): FormGroup<RulesForm> {
    return new FormGroup<RulesForm>({
      rules: new FormArray<FormGroup<RuleForm>>([])
    })
  }

  private generateNewGroupItem(data: Partial<IRule>, order?: number): FormGroup<RuleForm> {
    return new FormGroup<RuleForm>({
      title: new FormControl<string | null>(data.title, [Validators.required]),
      type: new FormControl<string | null>(RuleTypeEnum.ConditionalQuestion, [Validators.required]),
      action: new FormControl<string | null>(data.action, [Validators.required]),
      controlId: new FormControl<string | null>(data.controlId, [Validators.required]),
      controlResponseValueIndex: new FormControl<number | null>(data.controlResponseValueIndex, [Validators.required]),
      order: new FormControl<number>(order ?? this.rules?.length ?? 0),
      logic: new FormControl<string | null>(data.logic ?? null),
      condition: new FormControl<string | null>(data.condition ?? ''),
      additionalValue: new FormControl<any>(data.additionalValue ?? ''),
    });
  }

  private setControlRulesToTheForm(form: FormGroup<RulesForm>, currentControl: ControlModel): Observable<FormGroup<RulesForm>> {
    const conditions = currentControl.dependencies[RulesType.Conditions];
    if (!conditions) {
      return of(form);
    }

    Object.keys(conditions).forEach((action: string) => {
      conditions[action].data.forEach((rule: RuleModel) => {
        const group: FormGroup<RuleForm> = this.generateNewGroupItem(
          {
            title: rule.title,
            action,
            controlId: rule.triggeringControlId,
            controlResponseValueIndex: rule.value,
            logic: rule.logic,
            additionalValue: rule.additionalValue,
            condition: rule.condition
          },
          rule.order
        )
        form.controls.rules.insert(rule.order, group);
        if (!rule.condition) {
          group.controls.additionalValue.disable();
        }

        if (!isDropdownSelectionComponent(currentControl)) {
          group.controls.logic.setValidators(Validators.required);
        }
        // if condition is active then additionalValue should be required
        if (!!rule.condition) {
          group.controls.additionalValue.setValidators(Validators.required);
        }
      })
    });

    return of(form);
  }

  private populateRuleToTheControlState(): void {
    this.ruleForm$
      .pipe(
        switchMap((form) => form.valueChanges),
        filter(() => this.isFormValid(this.ruleForm)),
        withLatestFrom(this.allFormControls$),
        takeUntil(this.destroy$)
      ).subscribe(([formValues, controls]) => {
      const rules = {
        [RuleActionEnum.Hide]: {
          logic: RuleLogicEnum.And,
          data: [],
        },
        [RuleActionEnum.Show]: {
          logic: RuleLogicEnum.And,
          data: [],
        },


      };
      formValues.rules.forEach((rule, order) => {

        let value: any = rule.controlResponseValueIndex;
        const target = controls.find(c => c.controlID === rule.controlId);
        if (!target) {
          return;
        }
        let data = [
          ...rules[rule.action].data,
          {
            title: rule.title,
            type: rule.type,
            logic: rule.logic ?? RuleLogicEnum.Equal,
            triggeringControlId: rule.controlId,
            model: target.bindDataField,
            value,
            order,
            condition: rule.condition,
            additionalValue: rule.additionalValue
          }
        ];
        rules[rule.action] = {
          ...rules[rule.action],
          data
        }
      })
      const currentDependencies = getState(this.store).control.current.control.dependencies;
      const newDependencies = {
        ...currentDependencies,
        [RulesType.Conditions]: rules
      }
      this.store.dispatch(updateControlField({ field: 'dependencies', value: newDependencies }));
    })
  }

  private setFormValidObservable(): void {

    const isFormValid$ = this.ruleForm$.pipe(
      switchMap((form) => form.valueChanges.pipe(
        startWith(EMPTY),
        map(() => form))
      ),
      map((form) => this.isFormValid(form)),
      distinctUntilChanged(),
    );

    this.currentControlValidationService.setFormValidObservable(isFormValid$);

    this.currentControlValidationService.markFormAsTouched$
      .pipe(
        withLatestFrom(this.ruleForm$),
        filter(([_, form]) => !this.isFormValid(form)),
        takeUntil(this.destroy$)
      )
      .subscribe(([_, form]) => {
        form.markAllAsTouched();
      })
  }

  private isFormValid(form: FormGroup<RulesForm>): boolean {
    return form.valid;
  }

}
