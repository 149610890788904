<a
  *ngIf="navigateBackLinkInfo$ | async as navigateBackLinkInfo"
  [routerLink]="navigateBackLinkInfo.routerLink"
  class="phar-back-link">
  <mat-icon svgIcon="arrow-left"></mat-icon>
  {{ navigateBackLinkInfo.title }}
</a>

<div class="d-flex flex-column">
  <phar-second-level-menu [links]="secondLevelLinks"></phar-second-level-menu>

  <main class="context-mainContent">
    <router-outlet></router-outlet>
  </main>
</div>
