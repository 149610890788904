<div [formGroup]="parentFormGroup">
  <div class="row gx-0 mt-3">
    <div class="col-12">
      <mat-label>Rule title</mat-label>
      <div class="d-flex align-items-center">
        <mat-form-field class="w-100">
                      <textarea
                        class="text-area-scrollable"
                        formControlName="title"
                        cdkAutosizeMaxRows="20"
                        cdkAutosizeMinRows="1"
                        matInput
                        cdkTextareaAutosize
                        pharInputTrim
                        placeholder="Enter a rule title"
                        [maxLength]="500"
                        [pharMaxLengthTooltip]="500"
                      >
                      </textarea>
          <mat-error>
            Title is <strong>required</strong>
          </mat-error>
        </mat-form-field>


      </div>
    </div>
  </div>

  <ng-container
    *ngTemplateOutlet="valueTemplate; context: {$implicit: 'controlResponseValueIndex', hideLogic: false, }"/>

    <div class="col-12">
      <mat-label>Additional condition</mat-label>
      <mat-form-field class="w-100">
        <mat-select placeholder="Select additional condition" formControlName="condition">
          @for (option of conditions; let i = $index; track i) {
            <mat-option [value]="option.value">
              <span>{{ option.label }}</span>
            </mat-option>
          }

        </mat-select>
        <mat-error>
          Condition is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <ng-container
      *ngTemplateOutlet="valueTemplate; context: {$implicit: 'additionalValue', hideLogic: true}"/>

  <div class="row gx-0">
    <div class="col-12">
      <mat-label>Action</mat-label>
      <mat-form-field class="w-100">
        <mat-select formControlName="action" placeholder="Select action">
          @for (ruleAction of ruleActions; track $index) {
            <mat-option [value]="ruleAction.action">
              <span>{{ ruleAction.label }}</span>
            </mat-option>
          }
        </mat-select>

        <mat-error>
          Action is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row gx-0">
    <div class="col-12">
      <mat-label>Conditional question</mat-label>
      <mat-form-field class="w-100">
        <mat-select placeholder="Select conditional question"
                    formControlName="controlId">
          @for (control of formControls$ | async; track control.controlID) {
            <mat-option [value]="control.controlID">
              <span>{{ (control.title ?? control.label ?? 'N/A') | stripTags }} <i
                class="ms-1">({{ control.controlType | pharElementType }})</i></span>

            </mat-option>
          }
        </mat-select>

        <mat-error>
          Question is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>


</div>
<ng-template let-type #logicTemplate>
  <div class="col-12" [formGroup]="parentFormGroup">
    <mat-label>Response option requiring rule</mat-label>
    <mat-form-field class="w-100">
      <mat-select formControlName="logic" placeholder="Select logic">
        @for (option of LOGIC_DATASET[type] || []; let i = $index; track i) {
          <mat-option [value]="option.value">
            <span>{{ option.text }}</span>
          </mat-option>
        }

      </mat-select>
      <mat-error>
        Logic is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #valueTemplate let-field let-hideLogic="hideLogic">
  <div class="row gx-0" [formGroup]="parentFormGroup">

    @switch (selectionType) {

      @case (SelectionType.Dropdown) {
        <div class="col-12">
          <mat-label>Response option requiring rule</mat-label>
          <mat-form-field class="w-100">
            <mat-select [formControlName]="field" placeholder="Select response option requiring rule">
              @for (responseOption of responseOptions; let i = $index; track i) {
                <mat-option [value]="i">
                  <span>{{ (responseOption.label || responseOption.value)| stripTags }}</span>
                </mat-option>
              }

            </mat-select>

            <mat-error>
              Response is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

      }
      @case (SelectionType.String) {
        @if (!hideLogic) {
          <ng-container *ngTemplateOutlet="logicTemplate; context: {$implicit: SelectionType.String}"></ng-container>
        }


        <div class="col-12">
          <mat-label>Response option requiring rule</mat-label>
          <mat-form-field class="w-100">
            <input [formControlName]="field" matInput placeholder="Value">
            <mat-error>
              Response value is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

      }
      @case (SelectionType.Number) {
        @if (!hideLogic) {
          <ng-container *ngTemplateOutlet="logicTemplate; context: {$implicit: SelectionType.Number}"></ng-container>
        }
        <div class="col-12">
          <mat-label>Response option requiring rule</mat-label>
          <mat-form-field class="w-100">
            @if (isRangeSelected$ | async) {
              <phar-number-range [formControlName]="field" placeholder="Value"/>
            } @else {
              <input [formControlName]="field" matInput type="number" placeholder="Value">
            }
            <mat-error>
              Response value is <strong>required</strong>
            </mat-error>
          </mat-form-field>

        </div>

      }
      @case (SelectionType.Date) {
        @if (!hideLogic) {
          <ng-container *ngTemplateOutlet="logicTemplate; context: {$implicit: SelectionType.Date}"></ng-container>
        }

        <div class="col-12">
          @if (isRangeSelected$ | async) {
            <mat-label>Response option requiring rule</mat-label>
            <mat-form-field class="range-picker w-100">
              <phar-date-picker-value startLabel="Start date"
                                      endLabel="End date"
                                      [formControlName]="field"
                                      [picker]="picker"/>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
              <mat-error>
                Response value is <strong>required</strong>
              </mat-error>
            </mat-form-field>

          } @else {
            <mat-label>Response option requiring rule</mat-label>
            <mat-form-field class="w-100">
              <phar-datepicker-input [picker]="picker" [formControlName]="field"
                                     placeholder="Select date">
              </phar-datepicker-input>
              <mat-datepicker-toggle class="datepicker-toggle" matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error>
                Response value is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          }
        </div>

      }
      @case (SelectionType.Time) {
        @if (!hideLogic) {
          <ng-container *ngTemplateOutlet="logicTemplate; context: {$implicit: SelectionType.Date}"></ng-container>
        }
        <div class="col-12">
          @if (isRangeSelected$ | async) {
            <mat-label>Response option requiring rule</mat-label>
            <mat-form-field class="w-100">
              <phar-time-range-picker [formControlName]="field"></phar-time-range-picker>
              <mat-icon matSuffix>access_time</mat-icon>
              <mat-error>
                Response value is <strong>required</strong>
              </mat-error>
            </mat-form-field>

          } @else {
            <mat-label>Response option requiring rule</mat-label>
            <mat-form-field class="w-100">
              <phar-timepicker-field class="time-picker-field w-100" [withPeriod]="false" [showSeconds]="false"
                                     [formControlName]="field"/>
              <mat-icon matSuffix>access_time</mat-icon>
              <mat-error>
                Response value is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          }
        </div>

      }

    }
  </div>

</ng-template>

