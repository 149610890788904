import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/models/app.state';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { FormModel } from '../../../../form/form.model';
import { selectCurrentFormStateForm, selectCurrentFormStateFormSnapshot } from '../../../../form/store/form.state';
import { updateForm, updateFormField } from '../../../../form/store/form.actions';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  take,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { QuestionModule } from '../../../../question/question.module';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { SharedModule } from '../../../shared.module';
import { BaseComponent } from '../../../base.class';
import { INPUT_DEBOUNCE_TIME } from '../../../../core/config/app.constants';
import { PendingChangesService } from '../../../pending-changes.service';
import { CurrentControlValidationService } from '../../../services/current-control-validation.service';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'phar-header-editor',
  templateUrl: 'header-editor.component.html',
  styleUrls: ['header-editor.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    QuestionModule,
    CdkTextareaAutosize,
    FormsModule,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    SharedModule
  ],
})
export class HeaderEditorComponent extends BaseComponent implements OnInit, OnDestroy {
  headerForm = new FormGroup({
    text: new FormControl<string>('', [Validators.required]),
    isSiteLevel: new FormControl<boolean>(false),
  });
  currentForm$: Observable<FormModel>;
  currentFormSnapshot$: Observable<FormModel>;
  hasPendingChanges$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private pendingChangesService: PendingChangesService,
    private currentControlValidationService: CurrentControlValidationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.currentForm$ = this.store.select(selectCurrentFormStateForm);
    this.currentFormSnapshot$ = this.store.select(selectCurrentFormStateFormSnapshot);
    this.hasPendingChanges$ = combineLatest([this.currentForm$, this.currentFormSnapshot$])
      .pipe(
        map(forms => forms.map((form) => form.body.header)),
        map(([header, headerSnapshot]) => !isEqual(header, headerSnapshot)),
        distinctUntilChanged(),
      );

    this.headerForm.valueChanges
      .pipe(
        debounceTime(INPUT_DEBOUNCE_TIME),
        takeUntil(this.destroy$),
        withLatestFrom(this.currentForm$),
      )
      .subscribe(([headerFormValues, form]) => {
        const formBody: FormModel['body'] = {
          ...form.body,
          header: { ...headerFormValues },
        };
        this.store.dispatch(updateFormField({ field: 'body', value: formBody }))
      });

    this.currentControlValidationService.markFormAsTouched$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.headerForm.markAllAsTouched();
      });

    this.setHeaderFormValue();
    this.handlePendingChanges();
    this.setFormValidObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.pendingChangesService.hasPendingChanges = false;
    this.currentControlValidationService.resetFormValidObservables();
  }

  setHeaderFormValue(): void {
    this.currentForm$
      .pipe(
        take(1),
        map((form) => form.body.header),
      )
      .subscribe((header) => {
        this.headerForm.patchValue(header);
      });
  }

  saveHeaderChanges(): void {
    if (!this.currentControlValidationService.isEditorFormsValid) {
      this.currentControlValidationService.markFormAsTouched();
      return;
    }

    this.currentForm$
      .pipe(take(1))
      .subscribe((form) => {
        this.store.dispatch(updateForm({ form }))
      });
  }

  discardChanges(): void {
    this.pendingChangesService.triggerDiscardChanges();
  }

  private handlePendingChanges(): void {
    this.hasPendingChanges$
      .pipe(takeUntil(this.destroy$))
      .subscribe((hasPendingChanges) => {
        this.pendingChangesService.hasPendingChanges = hasPendingChanges;
      });

    this.pendingChangesService.confirmedSavedChanges$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.saveHeaderChanges();
        } else {
          this.pendingChangesService.triggerDiscardChanges();
        }
      });
  }

  private setFormValidObservable(): void {
    const isFormValid$ = this.headerForm.statusChanges.pipe(
      startWith(EMPTY),
      map(() => this.headerForm.valid),
    );

    this.currentControlValidationService.setFormValidObservable(isFormValid$);
  }
}
