<button class="close-icon" mat-icon-button (click)="close()">
  <mat-icon class="button-block_small-icon" svgIcon="close"></mat-icon>
</button>

<h1 class="text-center" mat-dialog-title>Select the destination page
  for {{ data.mode === 'duplicate' ? 'copying' : 'moving' }} the question</h1>

<div mat-dialog-content>
  @if (data.mode === 'duplicate') {
    <mat-radio-group
      [formControl]="copyTargetFormCtrl">
      @for (target of targetOptions; track index; let index = $index) {
        <mat-radio-button color="primary" [class.ms-2]="index" [value]="target.id">
          {{ target.label }}
        </mat-radio-button>
      }

    </mat-radio-group>
  }

  @if (copyTargetFormCtrl.value === CopyTargetEnum.AnotherPage) {
    <mat-form-field class="mt-3 w-100">
      <mat-select [formControl]="selectedPageFormCtrl" [placeholder]="'Select page'">
        @for (page of pages$ | async; track index; let count = $count; let index = $index) {
          <mat-option [value]="index">
            Page <b>{{ index + 1 }}</b> of <b>{{ count }}</b>
            @if (data.currentPageIndex === index) {
              (current)
            }
          </mat-option>
        }

      </mat-select>
    </mat-form-field>
  }
</div>
<div align="center" mat-dialog-actions>
  <button mat-stroked-button (click)="close()">Cancel</button>
  <button class="button-success" mat-raised-button (click)="handleAction()">
    <mat-icon [svgIcon]="'check'"></mat-icon>
    {{ data.mode === 'duplicate' ? 'Copy' : 'Move' }}
  </button>
</div>
