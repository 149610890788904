import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RuleForm } from '../conditional-rules-editor.component';
import { RuleActionEnum } from '../../../../../rule/rule-action.enum';
import { Observable } from 'rxjs';
import { ControlModel } from '../../../../control.model';
import { selectCurrentFormStateFormPopulatedQuestions } from '../../../../../form/store/form.state';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { QuestionModel } from '../../../../question.model';
import { BaseComponent } from '../../../../../shared/base.class';
import { AppState } from '../../../../../store/models/app.state';
import { Store } from '@ngrx/store';
import {
  getSelectionType, SelectionType
} from '../../selection-types';
import { LOGIC_DATASET } from '../../logic-dataset';
import { RuleLogicEnum } from '../../../../../rule/rule-logic.enum';
import { ElementsEnum } from '../../../../../form/form-elements.enum';

@Component({
  selector: 'phar-conditional-rule',
  templateUrl: 'conditional-rule.component.html',
  styleUrl: './conditional-rule.component.scss'
})

export class ConditionalRuleComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input({ required: true }) parentFormGroup: FormGroup<RuleForm>;
  @Input({ required: true }) control: ControlModel;

  formControls$: Observable<ControlModel[]>;
  isRangeSelected$: Observable<boolean>;
  responseOptions: { label?: string, value: any }[] = [];
  ruleActions = [
    {
      label: 'Hide Question',
      action: RuleActionEnum.Hide,
    },
    {
      label: 'Show Question',
      action: RuleActionEnum.Show,
    }
  ];
  selectionType: SelectionType;
  conditions: { label?: string, value: any }[] = [
    { value: '', label: 'N/A' },
    { value: RuleLogicEnum.And, label: 'And' },
    { value: RuleLogicEnum.Or, label: 'Or' },
  ];


  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.formControls$ = this.store.select(selectCurrentFormStateFormPopulatedQuestions)
      .pipe(
        map((questions: Record<string, QuestionModel>) => {
          if (this.control.inGroup) {
            const question = Object.values(questions).find((question: QuestionModel) => question.controls.find(control => control.controlID === this.control.controlID));
            if (!question) {
              return [];
            }
            return question.controls;
          }

          const nestedControls = Object.values(questions)
            .filter(question => !question.isGroup)
            .map((question) => question.controls as ControlModel[]);

          return nestedControls.reduce((acc, controls) => acc.concat(controls), []);
        }),
        map((controls: ControlModel[]) => controls.filter((control: ControlModel) => control.controlID !== this.control.controlID && control.controlType !== ElementsEnum.DropZone)),
      );

    this.selectionType = getSelectionType(this.control);
    if (this.selectionType === SelectionType.Dropdown) {
      this.responseOptions = this.getControlResponseOptions(this.control);
    }


    this.parentFormGroup.controls.logic.valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe((logic) => {
      this.parentFormGroup.controls.controlResponseValueIndex.setValue(null);
    });

    this.parentFormGroup.controls.condition.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((condition) => {
      const additionalValueFormCtrl = this.parentFormGroup.controls.additionalValue;
      if (!condition) {
        if (additionalValueFormCtrl.enabled) {
          additionalValueFormCtrl.clearValidators();
          additionalValueFormCtrl.setValue(null)
          additionalValueFormCtrl.disable();
          additionalValueFormCtrl.updateValueAndValidity();
        }
      } else {
        if (additionalValueFormCtrl.disabled) {
          additionalValueFormCtrl.enable();
          additionalValueFormCtrl.addValidators(Validators.required);
          additionalValueFormCtrl.updateValueAndValidity();
        }
      }
    });

    this.isRangeSelected$ = this.parentFormGroup.controls.logic.valueChanges.pipe(
      map((value) => value === RuleLogicEnum.NotBetween || value === RuleLogicEnum.Range),
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getControlResponseOptions(control: ControlModel): { label?: string, value: any }[] {
    return control.values.map(({ label, value }) => ({ label, value })) || [];
  }

  protected readonly SelectionType = SelectionType;
  protected readonly LOGIC_DATASET = LOGIC_DATASET;
}
