import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, Observable, of } from 'rxjs';
import { ControlModel } from '../../../control.model';
import { BaseComponent } from '../../../../shared/base.class';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { AppState, getState, selectCurrentControl } from '../../../../store/models/app.state';
import { Store } from '@ngrx/store';
import { RulesType } from '../../../rules-list/rules-type.enum';
import { RuleLogicEnum } from '../../../../rule/rule-logic.enum';
import { RuleTypeEnum } from '../../../../rule/rule-type.enum';
import { FormElementsEnum } from '../../../../form/form-elements.enum';
import { updateControlField } from '../../../../store/actions/control.actions';
import { IEditCheck } from './edit-check.interface';
import { CurrentControlValidationService } from '../../../../shared/services/current-control-validation.service';
import { LOGIC_DATASET } from '../logic-dataset';


export interface CheckForm {
  title: FormControl<string | null>;
  type: FormControl<string | null>;
  response: FormControl<any>;
  value: FormControl<any>;
  errorMessage: FormControl<string>;
}

interface ChecksForm {
  checks: FormArray<FormGroup<CheckForm>>
}

@Component({
  selector: 'phar-edit-checks-editor',
  templateUrl: 'edit-checks-editor.component.html',
  styleUrls: ['edit-checks-editor.component.scss', '../conditional-rules-editor/conditional-rules-editor.component.scss'],
})

export class EditChecksEditorComponent extends BaseComponent implements OnInit, OnDestroy{
  checksForm: FormGroup<ChecksForm>;
  checksForm$: Observable<FormGroup<ChecksForm>>;
  currentControl$: Observable<ControlModel>;
  openCloseState: { [key: number]: boolean } = {}
  controlType: string;
  ELEMENT_TYPES = {
    string: [
      FormElementsEnum.SingleSelect,
      FormElementsEnum.VerbalRatingScale,
      FormElementsEnum.TextInput,
    ],
    number: [FormElementsEnum.Number, FormElementsEnum.VisualAnalogScale, FormElementsEnum.NumericRatingScale],
    date: [
      FormElementsEnum.DatePicker,
    ],
    time: [
      FormElementsEnum.TimePicker
    ],
    boolean: [
      FormElementsEnum.Boolean
    ]
  }

  conditions$: Observable<{ text: string, value: RuleLogicEnum }[]>;


  constructor(private store: Store<AppState>, private currentControlValidationService: CurrentControlValidationService) {
    super();
  }

  get checks(): FormArray {
    return this.checksForm?.get('checks') as FormArray
  }

  ngOnInit() {
    this.currentControl$ = this.store.select(selectCurrentControl)
      .pipe(
        filter(({ controlID }) => !!controlID),
        distinctUntilChanged((prev, curr) => prev.controlID === curr.controlID),
      );

    this.checksForm$ = this.currentControl$
      .pipe(
        switchMap((control) => {
          const form: FormGroup<ChecksForm> = this.getInitialForm();
          return this.setControlRulesToTheForm(form, control);
        }),
        tap((formGroup) => this.checksForm = formGroup),
        shareReplay(1),
      );


    this.conditions$ = this.currentControl$.pipe(
      map((ctrl: ControlModel) => {
        this.controlType = this.getControlType(ctrl.controlType as FormElementsEnum);
        if (!this.controlType) {
          return []
        }
        return LOGIC_DATASET[this.controlType] ?? [];
      }),
      shareReplay(1)
    );

    this.populateRuleToTheControlState();
    this.setFormValidObservable();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getControlType(controlType: FormElementsEnum): string | null {
    let type = null
    const allTypes = Object.keys(this.ELEMENT_TYPES);

    for (let elType of allTypes) {
      if (this.ELEMENT_TYPES[elType].includes(controlType)) {
        type = elType;
        break;
      }
    }
    return type;

  }

  addCondition(length: number): void {
    this.checks.push(this.generateNewGroupItem(`Edit check ${length + 1}`));
    this.openCloseState[this.checks.controls.length - 1] = true
  }

  removeRule(index: number): void {
    this.checks.removeAt(index, { emitEvent: true });
    delete this.openCloseState[index];
  }

  toggleOpenCloseState(index: number): void {
    this.openCloseState[index] = !this.openCloseState[index]
  }

  private getInitialForm(): FormGroup<ChecksForm> {
    return new FormGroup<ChecksForm>({
      checks: new FormArray<FormGroup<CheckForm>>([])
    })
  }


  private setControlRulesToTheForm(form: FormGroup<ChecksForm>, currentControl: ControlModel): Observable<FormGroup<ChecksForm>> {
    const checks = currentControl.dependencies[RulesType.EditChecks];
    if (!checks || !checks.length) {
      return of(form);
    }

    checks.forEach((check: IEditCheck) => {
      form.controls.checks.push(this.generateNewGroupItem(check.title, check.type, check.errorMessage, check.response, check.value))
    });

    return of(form);
  }

  private populateRuleToTheControlState(): void {
    this.checksForm$
      .pipe(
        switchMap((form) => form.valueChanges),
        filter(() => this.checksForm.valid),
        withLatestFrom(this.currentControl$),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      ).subscribe(([formValues, control]) => {
      const checksValues = formValues.checks;
      let updatedChecks = [];

      if (checksValues.length) {
        updatedChecks = checksValues.map((check) => ({
          ...check,
          model: control.bindDataField,
          controlId: control.controlID,
        }));
      }


      const currentDependencies = getState(this.store).control.current.control.dependencies;

      const updatedDependencies = {
        ...currentDependencies,
        [RulesType.EditChecks]: updatedChecks
      }

      this.store.dispatch(updateControlField({
        field: 'dependencies',
        value: updatedDependencies
      }));
    })
  }

  private setFormValidObservable(): void {

    const isFormValid$ = this.checksForm$.pipe(
      switchMap((form) => form.valueChanges.pipe(
        startWith(EMPTY),
        map(() => form))
      ),
      map((form) => form.valid),
      distinctUntilChanged(),
    );

    this.currentControlValidationService.setFormValidObservable(isFormValid$);

    this.currentControlValidationService.markFormAsTouched$
      .pipe(
        withLatestFrom(this.checksForm$),
        filter(([_, form]) => form.invalid),
        takeUntil(this.destroy$)
      )
      .subscribe(([_, form]) => {
        form.markAllAsTouched();
      })
  }

  private generateNewGroupItem(title: string = 'Check', type = RuleTypeEnum.EditCheck, errorMessage = null, response = null, value = null): FormGroup<CheckForm> {
    return new FormGroup<CheckForm>({
      title: new FormControl<string | null>(title, [Validators.required]),
      type: new FormControl<string | null>(type, [Validators.required]),
      errorMessage: new FormControl<string>(errorMessage, [Validators.required]),
      response: new FormControl<any>(response, [Validators.required]),
      value: new FormControl<any>(value, [Validators.required]),
    });
  }

}
