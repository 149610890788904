import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { errorPopup } from '../../../store/actions/ui.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/models/app.state';
import { updateControlSettingsField } from '../../../store/actions/control.actions';
import { CurrentControlValidationService } from '../../../shared/services/current-control-validation.service';
import { FormElementsEnum } from '../../../form/form-elements.enum';
import { QuestionEditorBaseComponent } from '../question-editor-base/question-editor-base.component';

@Component({
  selector: 'phar-image-editor',
  templateUrl: 'image-editor.component.html',
  styleUrls: ['image-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageEditorComponent extends QuestionEditorBaseComponent implements OnInit, OnDestroy {
  @Input() parentDataField = '';
  @Input() bindType: string;
  private acceptedFilesMessage: string;
  private readonly acceptedFiles = ['image/png', 'image/jpeg'];
  private readonly maxSizeInMegabytes = 2;
  private readonly maxSizeInBytes = this.maxSizeInMegabytes * 1024 * 1024;

  constructor(
    protected store: Store<AppState>,
    private currentControlValidationService: CurrentControlValidationService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.acceptedFilesMessage = this.acceptedFiles
      .map((format) => `*.${format.split('/').pop()}`)
      .join(', ');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.currentControlValidationService.resetFormValidObservables();
  }

  fileChange(event: Event): void {
    event.stopImmediatePropagation();
    event.preventDefault();
    const fileList: FileList = event.target['files'];

    if (fileList.length === 0) {
      return;
    }

    const file: File = fileList[0];
    if (!this.acceptedFiles.includes(file.type)) {
      this.store.dispatch(errorPopup({ error: `You can upload only ${this.acceptedFilesMessage} files` }));
      return;
    }

    if (file.size > this.maxSizeInBytes) {
      this.store.dispatch(errorPopup({ error: `You can upload only files under ${this.maxSizeInMegabytes} MB` }));
      return;
    }

    const fileReader = new FileReader();
    fileReader.addEventListener('load', ({ target }) => {
      this.store.dispatch(updateControlSettingsField('backgroundImage', target['result']));
    });
    fileReader.readAsDataURL(file);
  }

  protected readonly FormElementsEnum = FormElementsEnum;
}
