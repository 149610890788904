import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

import { AppState, getState } from '../../../store/models/app.state';
import { updateControlSettingsField } from '../../../store/actions/control.actions';
import { DataFieldBindingService } from '../bind-data-field-on-label-change/data-field-binding.service';
import { EMPTY } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { controlValueChanged } from '../../store/question.actions';
import { DropdownModel } from '../../../shared/models/dropdown-model';
import { CurrentControlValidationService } from '../../../shared/services/current-control-validation.service';
import { CONTROL_LABELS_MAX_LENGTH, INPUT_DEBOUNCE_TIME } from '../../../core/config/app.constants';
import { FormElementsEnum } from '../../../form/form-elements.enum';
import { ValidationsService } from '../../../core/helpers/validations.service';
import { QuestionEditorBaseComponent } from '../question-editor-base/question-editor-base.component';

enum LabelPosition {
  MinAndMaxOnly = 'mm',
  MinMaxAndMiddle = 'mmm',
}

@Component({
  selector: 'phar-numeric-rating-scale-editor',
  templateUrl: './numeric-rating-scale-editor.component.html',
  styleUrls: ['./numeric-rating-scale-editor.component.scss']
})
export class NumericRatingScaleEditorComponent extends QuestionEditorBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() parentDataField = '';
  @Input() bindType: string;
  form: UntypedFormGroup;
  LabelPosition = LabelPosition;
  readonly labelMaxLength = CONTROL_LABELS_MAX_LENGTH;

  labelDisplayOptions: DropdownModel[] = [
    {
      label: 'Min and Max label only',
      value: LabelPosition.MinAndMaxOnly
    },
    {
      label: 'Min, Max and Middle label',
      value: LabelPosition.MinMaxAndMiddle
    }
  ]

  labelsMinMax = [
    'Label - Min (optional)',
    'Label - Max (optional)',
  ];

  labelsMinMidMax = [
    'Label - Min (optional)',
    'Label - Middle (optional)',
    'Label - Max (optional)',
  ];

  constructor(
    private actions$: Actions,
    private fb: UntypedFormBuilder,
    protected store: Store<AppState>,
    private dataFieldBindingService: DataFieldBindingService,
    private currentControlValidationService: CurrentControlValidationService,
    private validationsService: ValidationsService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildForm();
    this.updateStateOnValueChange();
    this.setFormValidObservable();

    this.currentControlValidationService.markFormAsTouched$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.form.markAllAsTouched();
      });
  }

  ngAfterViewInit(): void {
    this.actions$.pipe(
      ofType<ReturnType<typeof controlValueChanged>>(controlValueChanged),
      takeUntil(this.destroy$),
    ).subscribe((data) => {
      if (data.shouldUpdateDataField) {
        this.dataFieldBindingService.bindDataFieldToTheControl(data.control);
      }
    })
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.currentControlValidationService.resetFormValidObservables();
  }

  getLabelControls(): FormControl<string>[] {
    return (this.form.get('labels') as UntypedFormArray).controls as FormControl<string>[];
  }

  private buildForm(): void {
    const settings = getState(this.store).control.current.control.settings
    this.form = this.fb.group({
      min: [settings.min, [Validators.required, Validators.min(0), Validators.max(20)]],
      max: [settings.max, [Validators.required, Validators.min(0), Validators.max(20)]],
      orientation: [settings.orientation],
      // showValueInterval: [ settings.showValueInterval, [ Validators.required ] ],
      labelInterval: [settings.labelInterval],
      labels: this.fb.array(settings.labels.map((label) => this.fb.control(label, Validators.maxLength(this.labelMaxLength))), this.validationsService.uniqueLabelsValidator()),
    }, {
      validators: [this.validationsService.minMaxValidator]
    });
  }

  private setFormValidObservable(): void {
    const isFormValid$ = this.form.statusChanges.pipe(
      startWith(EMPTY),
      map(() => this.form.valid),
    );

    this.currentControlValidationService.setFormValidObservable(isFormValid$);
  }

  private updateLabels(value: LabelPosition): void {
    if (value === LabelPosition.MinAndMaxOnly) {
      const controls: UntypedFormArray = this.form.get('labels') as UntypedFormArray;
      controls.removeAt(1);
    } else {
      const controls: UntypedFormArray = this.form.get('labels') as UntypedFormArray;
      controls.insert(1, this.fb.control(``));
    }
  }

  private updateStateOnValueChange(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).valueChanges
        .pipe(
          distinctUntilChanged(),
          debounceTime(INPUT_DEBOUNCE_TIME),
          takeUntil(this.destroy$),
        ).subscribe((value) => {
        if (key === "labelInterval") {
          this.updateLabels(value)
        }
        this.store.dispatch(updateControlSettingsField(key, value));

        // Added mid value for backend json export.
        if (this.form.get('labels')['length'] === 3) {
          this.store.dispatch(updateControlSettingsField('mid', +(this.form.get('max').value - 1)));
        } else {
          this.store.dispatch(updateControlSettingsField('mid', null));
        }
      });
    });
  }

  protected readonly FormElementsEnum = FormElementsEnum;
}
