import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { of, switchMap } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { DataFieldBindingService } from '../bind-data-field-on-label-change/data-field-binding.service';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { controlValueChanged } from '../../store/question.actions';
import { CurrentControlValidationService } from '../../../shared/services/current-control-validation.service';
import { DropdownModel } from '../../../shared/models/dropdown-model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppState, getState } from '../../../store/models/app.state';
import { Store } from '@ngrx/store';
import { INPUT_DEBOUNCE_TIME } from '../../../core/config/app.constants';
import { updateControlSettingsField } from '../../../store/actions/control.actions';
import { QuestionService } from '../../question.service';
import { PharConfirmDialogService } from '../../../shared/confirm-dialog/confirm-dialog-service.service';
import { FormElementsEnum } from '../../../form/form-elements.enum';
import { QuestionEditorBaseComponent } from '../question-editor-base/question-editor-base.component';

@Component({
  selector: 'phar-date-picker-editor',
  templateUrl: 'date-picker-editor.component.html',
  styleUrls: ['date-picker-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerEditorComponent extends QuestionEditorBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() parentDataField = '';
  @Input() bindType: string;

  excludedOptionsForm: UntypedFormGroup;

  excludedDateOptions: DropdownModel[] = [
    {
      label: 'Day',
      value: 'day'
    },
    {
      label: 'Month',
      value: 'month'
    },
    {
      label: 'Year',
      value: 'year'
    }
  ];

  constructor(
    private actions$: Actions,
    private currentControlValidationService: CurrentControlValidationService,
    private dataFieldBindingService: DataFieldBindingService,
    private formBuilder: UntypedFormBuilder,
    protected store: Store<AppState>,
    private questionService: QuestionService,
    private confirmDialogService: PharConfirmDialogService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildForm();
    this.updateStateOnValueChange();
  }

  ngAfterViewInit(): void {
    this.actions$.pipe(
      ofType<ReturnType<typeof controlValueChanged>>(controlValueChanged),
      takeUntil(this.destroy$),
    ).subscribe((data) => {
      if (data.shouldUpdateDataField) {
        this.dataFieldBindingService.bindDataFieldToTheControl(data.control);
      }
    })
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.currentControlValidationService.resetFormValidObservables();
    this.questionService.setSaveControlBeforeSaveQuestion(false);
  }

  private buildForm(): void {
    const settings = getState(this.store).control.current.control.settings;

    this.excludedOptionsForm = this.formBuilder.group({
      year: [settings.year],
      month: [settings.month],
      day: [settings.day],
    });
  }

  private updateStateOnValueChange(): void {
    Object.keys(this.excludedOptionsForm.controls).forEach((key) => {
      this.excludedOptionsForm
        .get(key)
        .valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(INPUT_DEBOUNCE_TIME),
        switchMap((value) => {
          if (
            this.excludedOptionsForm.controls.year.value === true &&
            this.excludedOptionsForm.controls.month.value === false &&
            this.excludedOptionsForm.controls.day.value === true
          ) {
            const question = `Are you sure you want to collect information on day and year only, without the month?`;
            return this.confirmDialogService
              .openConfirmDialog(question, "", "Yes", "No", false, 350)
              .pipe(
                take(1),
                tap((isConfirmed) => {
                  if (!isConfirmed) {
                    this.excludedOptionsForm.get(key).patchValue(!value);
                  }
                }),
                filter((isConfirmed) => isConfirmed),
                map((isConfirmed) => isConfirmed ? value : !value),
              );
          }

          return of(value);
        }),
        takeUntil(this.destroy$),
      )
        .subscribe((value) => {
          this.store.dispatch(updateControlSettingsField(key, value));
        });
    });
  }

  protected readonly FormElementsEnum = FormElementsEnum;
}
