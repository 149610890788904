/* src/app/shared/mfm-controls/mfm-date/phar-datepicker-input/phar-datepicker-input.component.scss */
input {
  border: none;
  outline: none;
}
input:focus {
  border: none;
  outline: none;
}
/*# sourceMappingURL=phar-datepicker-input.component.css.map */
