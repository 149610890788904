import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, getState } from '../../store/models/app.state';
import { Observable, of, Subject } from 'rxjs';
import { FormModel, FormStatusEnum } from '../form.model';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { updateForm, updateFormField } from '../store/form.actions';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { ApprovalStatusType } from '../../shared/approvals/approvals.component';
import { selectCurrentFormStateForm } from '../store/form.state';
import { UtilsService } from '../../core/utils.service';


@Component({
  selector: 'phar-form-approvals',
  templateUrl: 'form-approvals.component.html',
  styleUrls: ['form-approvals.component.scss'],
})
export class FormApprovalsComponent implements OnInit, OnDestroy {

  form$: Observable<FormModel>;
  private readonly destroy$ = new Subject<null>();

  constructor(
    private store: Store<AppState>,
    private confirmDialog: PharConfirmDialogService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService,
  ) {
  }

  ngOnInit(): void {

    this.form$ = this.store.select(selectCurrentFormStateForm).pipe(
      distinctUntilChanged(),
    );

  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  handleStatusChange(event: { status: ApprovalStatusType }): void {
    this.setFormStatus(event.status as FormStatusEnum, true);
  }

  setFormStatus(formStatus: FormStatusEnum, isLocked: boolean): void {
    this.form$
      .pipe(
        take(1),
        switchMap((form: FormModel) => this.utilsService.formExportApprovalEmptyQuestionValidation(form,
          this.confirmDialog,
          this.router,
          'approve')),
        filter(form => !!form),
        switchMap((form) => {
          if (formStatus !== FormStatusEnum.Released) {
            return of(form);
          }

          return this.utilsService.formExportApprovalUnresolvedCommentsValidation(form, this.confirmDialog, this.router)
        }),
        switchMap((form) => {
          if (formStatus !== FormStatusEnum.Released) {
            return of(form);
          }

          return this.confirmDialog.openConfirmDialog(
            `Have all reviewers approved the questionnaire?`,
            `${form.name} V${form.version}`,
            'Yes',
            'No',
          )
            .pipe(
              filter(isConfirmed => !!isConfirmed),
              switchMap(() => this.confirmDialog.openConfirmDialog(
                `Do you want to update the version number to ${this.utilsService.incrementVersion(form.version, 'major')}?`,
                `${form.name} V${form.version}`,
                'Yes',
                'No',
              )),
              tap((isConfirmed) => {
                if (isConfirmed) {
                  this.store.dispatch(updateFormField({
                    field: 'version',
                    value: this.utilsService.incrementVersion(form.version, 'major')
                  }));
                }
              }),
              tap(() => this.confirmDialog.openConfirmDialog(
                '',
                `${form.name} is now released and has moved to the library.`,
                'Got it',
                '',
              )),
              map(() => form),
            );

        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const currentDate: string = new Date().toJSON();
        const dateFieldToUpdate: string = (() => {
          switch (true) {
            case formStatus === FormStatusEnum.PendingReview:
              return 'dateSentForReview';
            case formStatus === FormStatusEnum.UnderReview:
              return 'dateReviewStarted';
            case formStatus === FormStatusEnum.Released:
              return 'releaseDate';
          }
        })();

        if (dateFieldToUpdate) {
          this.store.dispatch(updateFormField({ field: dateFieldToUpdate, value: currentDate }));
        }

        this.store.dispatch(updateFormField({ field: 'formStatus', value: formStatus }));
        this.store.dispatch(updateFormField({ field: 'isLocked', value: isLocked }));
        this.store.dispatch(updateForm({ form: getState(this.store).form.current.form }));
      });
  }

  protected readonly FormStatusEnum = FormStatusEnum;
}
