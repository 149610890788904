<div class="editor-container">
  <form class="editor-content" [formGroup]="headerForm">
    <mat-label class="required-field">Header text</mat-label>
    <mat-form-field class="w-100">
    <textarea class="text-area-scrollable"
              cdkAutosizeMaxRows="20"
              cdkAutosizeMinRows="1"
              matInput
              cdkTextareaAutosize
              pharInputTrim
              placeholder="Enter header text"
              formControlName="text"
              [maxlength]="2000"
              [pharMaxLengthTooltip]="2000"
    ></textarea>
      <mat-error>
        Header is <b>required</b>
      </mat-error>
    </mat-form-field>
    <div>
      <mat-label>Site level</mat-label>
      <mat-slide-toggle color="primary" formControlName="isSiteLevel">
      </mat-slide-toggle>
    </div>
  </form>
  <div class="editor-actions d-flex align-items-center">
    <button class="me-auto btn-small" mat-raised-button [disabled]="(hasPendingChanges$ | async) === false" (click)="discardChanges()">
      <mat-icon [svgIcon]="'close'"></mat-icon>
      <b>Discard</b>
    </button>

    <button mat-raised-button
            class="ms-auto btn-small button-success"
            (click)="saveHeaderChanges()"
            [disabled]="(hasPendingChanges$ | async) === false"
    >
      <mat-icon svgIcon="check"></mat-icon>
      <b>Save</b>

    </button>
  </div>
</div>
