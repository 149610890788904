<div class="editor-container" [formGroup]="form">
  <div class="editor-content">
    <mat-label>Group Name/ID</mat-label>
    <mat-form-field class="w-100">
    <textarea class="text-area-scrollable"
              cdkAutosizeMaxRows="20"
              cdkAutosizeMinRows="1"
              matInput
              cdkTextareaAutosize
              pharInputTrim
              placeholder="Enter a group Name/ID"
              [maxlength]="250"
              [pharMaxLengthTooltip]="250"
              formControlName="title"
    >
    </textarea>

      <mat-error>
        Group Name/ID is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-label>Group title (optional)</mat-label>
    <mat-form-field class="w-100">
    <textarea class="text-area-scrollable"
              formControlName="label"
              cdkAutosizeMaxRows="20"
              cdkAutosizeMinRows="1"
              matInput
              cdkTextareaAutosize
              pharInputTrim
              placeholder="Enter a Group title"
              [maxlength]="250"
              [pharMaxLengthTooltip]="250"
    >
    </textarea>

    </mat-form-field>
    <mat-label>Optional</mat-label>
    <mat-form-field>
      <textarea class="text-area-scrollable"
                formControlName="info"
                cdkAutosizeMaxRows="20"
                cdkAutosizeMinRows="1"
                matInput
                cdkTextareaAutosize
                pharInputTrim
                placeholder="Optional information"
                [maxlength]="500"
                [pharMaxLengthTooltip]="500"
      >
      </textarea>

    </mat-form-field>
    <div class="mb-3">
      <mat-label>Repeatable</mat-label>
      <mat-slide-toggle color="primary" formControlName="isRepeatable">
      </mat-slide-toggle>
    </div>
    @if (form.controls.isRepeatable.value) {
      <mat-label>Number of allowed iterations</mat-label>
      <mat-form-field class="w-100">
        <input type="number" formControlName="allowedIterations" matInput placeholder="Place your number" [min]="0"
               [step]="1"/>
        <mat-error>
          @if (form.controls.allowedIterations.hasError('required')) {
            Number of allowed iterations is <strong>required</strong>
          }

          @if (form.controls.allowedIterations.hasError('min')) {
            Number of allowed iterations minimum is <strong>1</strong>
          }
        </mat-error>
      </mat-form-field>

      @if (formType === FormTypeEnum.Questionnaire) {
        <div class="mt-2">
          <mat-label>Repeat group on a new page</mat-label>
          <mat-slide-toggle color="primary" formControlName="iterationOnNewPage">
          </mat-slide-toggle>
        </div>
      }
    }
  </div>

  <div class="editor-actions d-flex align-items-center">
    <button class="me-auto btn-small" mat-raised-button [disabled]="!(hasPendingChanges$ | async)" (click)="triggerDiscard()">
      <mat-icon [svgIcon]="'close'"></mat-icon>
      <b>Discard</b>
    </button>

    <button mat-raised-button
            class="ms-auto btn-small button-success"
            (click)="saveGroup()"
            [disabled]="!(hasPendingChanges$ | async)"
    >
      <mat-icon svgIcon="check"></mat-icon>
      <b>Save</b>

    </button>
  </div>

</div>


