import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataFieldBindingService } from '../bind-data-field-on-label-change/data-field-binding.service';
import { Actions, ofType } from '@ngrx/effects';
import { controlValueChanged } from '../../store/question.actions';
import { CurrentControlValidationService } from '../../../shared/services/current-control-validation.service';
import {FormElementsEnum} from "../../../form/form-elements.enum";
import { QuestionEditorBaseComponent } from '../question-editor-base/question-editor-base.component';

@Component({
  selector: 'phar-verbal-rating-scale-editor',
  templateUrl: 'verbal-rating-scale-editor.component.html',
  styleUrls: ['verbal-rating-scale-editor.component.scss'],
})
export class VerbalRatingScaleEditorComponent extends QuestionEditorBaseComponent implements OnDestroy, AfterViewInit {
  @Input() parentDataField = '';
  @Input() bindType: string;

  constructor(
    private actions$: Actions,
    private dataFieldBindingService: DataFieldBindingService,
    private currentControlValidationService: CurrentControlValidationService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.actions$.pipe(
      ofType<ReturnType<typeof controlValueChanged>>(controlValueChanged),
      takeUntil(this.destroy$),
    ).subscribe((data) => {
      if (data.shouldUpdateDataField) {
        this.dataFieldBindingService.bindDataFieldToTheControl(data.control);
      }
    })
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.currentControlValidationService.resetFormValidObservables();
  }

    protected readonly FormElementsEnum = FormElementsEnum;
}
