import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { FormModel, FormStatusEnum, FormTypeEnum } from '../form.model';
import { CurrentFormManage } from '../store/form.state';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AppState, getState } from '../../store/models/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';

import {
  createForm,
  dispatchedCreateUpdateForm,
  pendingCreateUpdateForm,
  populateCurrentFormSnapshot,
  refreshCurrentForm,
  successCreateUpdateForm,
  updateForm,
  updateFormField,
} from '../store/form.actions';
import { globalLoading, updateContextTitle } from '../../store/actions/ui.actions';
import { UtilsService } from '../../core/utils.service';
import { PendingChangesControl } from "../../shared/guards/pending-changes.guard";
import { cloneDeep, isEqual, omit } from "lodash-es";
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { FormService } from '../form.service';
import { updateQuestions } from '../../question/store/question.actions';
import { QuestionModel } from '../../question/question.model';
import { ILanguage } from '../../shared/models/language.interface';
import { loadSettings, selectLanguages } from '../../store/actions/settings.actions';
import { BaseComponent } from '../../shared/base.class';
import { loadProjectList, selectProjectList } from '../../project/store/project.actions';
import { ProjectModel } from '../../project/project.model';
import { VERSION_PATTERN } from '../../core/config/app.constants';
import { AppConfig } from '../../core/config/app.config';

type EditableFormDetails = Pick<FormModel, 'name' | 'description' | 'type' | 'version' | 'languageId' | 'studyId' | 'isMandatoryToSign'>;

@Component({
  templateUrl: './form-details.component.html',
  styleUrls: ['./form-details.component.scss']
})
export class FormDetailsComponent extends BaseComponent implements OnInit, OnDestroy, PendingChangesControl {
  form: UntypedFormGroup;
  formId: number;
  formTypes: { name: string, type: FormTypeEnum }[];
  inProjectContext = false;
  isNewForm = false;
  formState$: Observable<CurrentFormManage>;
  currentForm$: Observable<FormModel>;
  currentFormSnapshot$: Observable<FormModel>;
  originalForm$: Observable<FormModel | null>;
  amendForm$: Observable<FormModel | null>;
  projects$: Observable<ProjectModel[]>;
  languages$: Observable<ILanguage[]>;
  hasPendingChanges$: Observable<boolean>;
  globalLoading$: Observable<boolean>;
  readonly formStatusEnum = FormStatusEnum;
  readonly DEFAULT_VERSION: string = '0.1';
  readonly MIN_NEW_FORM_VERSION: string = '0';
  appConfig = inject(AppConfig)

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>,
    private readonly permissionService: NgxPermissionsService,
    private readonly utilsService: UtilsService,
    private readonly confirmDialog: PharConfirmDialogService,
    private readonly formService: FormService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.globalLoading$ = this.store.select(state => state.ui.globalLoading);
    if ((this.route.snapshot.data as { isContext?: boolean }).hasOwnProperty('isContext')) {
      this.inProjectContext = this.route.snapshot.data.isContext;
    }
    if ((this.route.snapshot.data as { isNewForm?: boolean }).hasOwnProperty('isNewForm')) {
      this.isNewForm = this.route.snapshot.data.isNewForm;
    }

    this.formTypes = [
      {
        name: 'eClinRO',
        type: FormTypeEnum.Questionnaire,
      },
      {
        name: 'ePRO',
        type: FormTypeEnum.DataCapture,
      },
      // {
      //   name: 'eObsRO',
      //   type: FormTypeEnum.EObsRO,
      // },
      {
        name: 'Informed Consent',
        type: FormTypeEnum.InformedConsent,
      },
    ];
    this.formState$ = this.store.select(state => state.form.current);
    this.currentForm$ = this.formState$.pipe(map(current => current.form), distinctUntilChanged(isEqual));
    this.currentFormSnapshot$ = this.formState$.pipe(map(current => current.formSnapshot), distinctUntilChanged(isEqual));
    this.hasPendingChanges$ = combineLatest([this.currentForm$, this.currentFormSnapshot$])
      .pipe(
        map((forms) => {
          return forms.map((form: FormModel): EditableFormDetails => {
            const { name, type, version, description, studyId, languageId, isMandatoryToSign } = form;
            return { name, type, version, description, studyId, languageId, isMandatoryToSign };
          })
        }),
        map(([editableFormDetails, editableFormDetailsSnapshot]) => {
          return !isEqual(editableFormDetails, editableFormDetailsSnapshot);
        }),
        distinctUntilChanged(),
      );
    this.originalForm$ = this.currentForm$.pipe(
      map((form) => form.formDuplicateId),
      distinctUntilChanged(),
      switchMap((formDuplicateId) => {
        if (!formDuplicateId) {
          return of(null);
        }

        return this.formService.searchById(formDuplicateId);
      }),
    );
    this.amendForm$ = this.currentForm$.pipe(
      map((form) => form.formAmendId),
      distinctUntilChanged(),
      switchMap((formAmendId) => {
        if (!formAmendId) {
          return of(null);
        }

        return this.formService.searchById(formAmendId);
      }),
    );
    this.languages$ = this.store.select(selectLanguages).pipe(
      map((languages) => languages.filter((lang) => this.appConfig.config.allowedLanguageCodes.includes(lang.code))),
    );
    this.projects$ = this.store.select(selectProjectList);

    this.createInitialForm();
    this.synchronizeWithStore();
    this.store.dispatch(loadSettings());
    this.store.dispatch(loadProjectList());
    this.store.dispatch(successCreateUpdateForm({ success: false }));
  }

  ngOnDestroy(): void {
    if (getState(this.store).form.current.pendingChanges && !this.isNewForm) {
      this.store.dispatch(refreshCurrentForm());
    }
    super.ngOnDestroy();
  }

  saveAndCheckPendingChanges(): Observable<boolean> {
    //this method is used in PendingChangesGuard to save pending changes
    //and to check if component can be deactivated
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return of(true);
    }
    this.store.dispatch(globalLoading(true));

    if (this.isNewForm) {
      const currentForm = getState(this.store).form.current.form
      const isInformedConsent = currentForm.type === FormTypeEnum.InformedConsent;

      const form: FormModel = {
        ...currentForm,
        body: {
          ...currentForm.body,
          pages: [{
            title: 'Page 1',
            pageID: 'P-1',
            questions: [],
            buttons: [],
            page_uuid: this.utilsService.generateUUID(),
          }]
        },
        formStatus: FormStatusEnum.Draft,
        isLocked: false,
        ...(isInformedConsent && {
          settings: { ...currentForm.settings, showHeader: true, showFooter: true },
        }),
      };

      this.utilsService.dispatchActions(this.store, [
        pendingCreateUpdateForm({ pending: true }),
        dispatchedCreateUpdateForm({ dispatched: true }),
        createForm({ form }),
      ]);
    } else {

      const { form, formSnapshot } = cloneDeep(getState(this.store).form.current);
      const questionsToUpdated: QuestionModel[] = [];
      if (formSnapshot.type === FormTypeEnum.Questionnaire && form.type !== FormTypeEnum.Questionnaire) {
        Object.keys(form.questionsPopulated).forEach(questionId => {
          if (form.questionsPopulated[questionId].iterationOnNewPage) {
            form.questionsPopulated[questionId].iterationOnNewPage = false
            questionsToUpdated.push(form.questionsPopulated[questionId]);
          }
        })
      }
      this.form.value.id = getState(this.store).form.current.form.id;
      let actions: Action[] = [
        pendingCreateUpdateForm({ pending: true }),
        dispatchedCreateUpdateForm({ dispatched: true }),
        updateForm({ form }),
      ];
      if (questionsToUpdated.length) {
        actions.unshift(updateQuestions({ questions: questionsToUpdated }));
      }

      this.utilsService.dispatchActions(this.store, actions);
    }


    return this.formState$.pipe(
      filter(({ dispatched, success }) => dispatched && success),
      tap((currentForm) => {
        this.store.dispatch(updateContextTitle({
          title: currentForm.form.name,
          position: this.inProjectContext || this.isNewForm ? 2 : 1
        }));
        this.utilsService.dispatchActions(this.store, [
          pendingCreateUpdateForm({ pending: false }),
          successCreateUpdateForm({ success: false })
        ]);
      }),
      switchMap(() => this.hasPendingChanges$),
      take(1),
    );
  }

  addEditForm(): void {
    this.saveAndCheckPendingChanges()
      .pipe(
        filter((hasPendingChanges) => !hasPendingChanges && this.isNewForm),
        // switchMap(() => this.confirmDialog.openConfirmDialog( // This should stay commented for now
        //   'Navigate to the Questions tab to begin adding items to your form.',
        //   '',
        //   'Confirm',
        //   '',
        //   false,
        //   300,
        //   true
        // )),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        // navigate when new form is created
        this.router.navigate(['../../edit/' + getState(this.store).form.current.form.id], { relativeTo: this.route });
      });
  }

  returnToDraftAndUnlockForm(form: FormModel): void {
    const proposedNewVersion = this.utilsService.incrementVersion(form.version, 'minor');

    this.confirmDialog.openConfirmDialog(
      `Do you want to update the version number to ${proposedNewVersion}?`,
      `${form.name} V${form.version}`,
      'Yes',
      'No',
    )
      .pipe(take(1))
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.store.dispatch(updateFormField({ field: 'version', value: proposedNewVersion }));
          this.form.controls.version.setValue(proposedNewVersion);
        }

        this.store.dispatch(updateFormField({ field: 'formStatus', value: FormStatusEnum.Draft }));
        this.store.dispatch(updateFormField({ field: 'isLocked', value: false }));
        this.addEditForm();
      });
  }

  originalFormClick(originalForm: FormModel): void {
    const module = originalForm.formStatus === FormStatusEnum.Released
      ? 'library'
      : 'workspace';

    this.router.navigate(
      [`../../../${module}`, 'edit', originalForm.id],
      { relativeTo: this.route.parent },
    );
  }

  /**
   * create the form
   */

  createInitialForm(): void {

    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(500)]],
      description: ['', Validators.maxLength(500)],
      type: [null, Validators.required],
      version: [this.DEFAULT_VERSION, [Validators.required, Validators.pattern(VERSION_PATTERN), this.minVersionValidator(this.MIN_NEW_FORM_VERSION)]],
      languageId: [null, Validators.required],
      studyId: [null],
      isMandatoryToSign: [{ value: false, disabled: true }],
    });

    if (this.isNewForm) {
      const formBody = getState(this.store).form.current.form.body;
      const formPages = formBody.pages.map(page => ({ ...page, page_uuid: this.utilsService.generateUUID() }));
      this.store.dispatch(updateFormField({ field: 'body', value: { ...formBody, pages: formPages } }));
      this.store.dispatch(updateFormField({ field: 'version', value: this.DEFAULT_VERSION }));
      this.store.dispatch(populateCurrentFormSnapshot({ form: getState(this.store).form.current.form }));
    }

    // this.permissionService.hasPermission('FormEdit').then(value => {
    //   if (!value) {
    //     this.form.disable();
    //   }
    // });
  }

  /**
   * sync with store
   */
  synchronizeWithStore(): void {
    this.currentFormSnapshot$
      .pipe(
        mergeMap(() => this.currentForm$.pipe(take(1))),
        filter(() => !this.isNewForm),
        takeUntil(this.destroy$),
      )
      .subscribe((form: FormModel) => {
        const updatedForm: FormModel = form;
        this.formId = form.id;
        this.form.patchValue(updatedForm, { emitEvent: false });
        this.form.get('version').setValidators([Validators.required, Validators.pattern(VERSION_PATTERN), this.minVersionValidator(updatedForm.version)]);

        if (form.isLocked || form.isArchived) {
          this.form.disable();
        } else {
          this.form.enable();
          this.form.get('type')?.disable();

          if (form.type !== FormTypeEnum.InformedConsent) {
            this.form.get('isMandatoryToSign').disable();
          }
        }
      });

    this.form.get('name').valueChanges
      .pipe(
        debounceTime(500),
        map((name) => name?.trim()),
        takeUntil(this.destroy$),
      )
      .subscribe(title => {
        this.store.dispatch(updateFormField({ field: 'name', value: title }));
        if (this.inProjectContext || this.isNewForm) {
          this.store.dispatch(updateContextTitle({ title, position: 2 }));
        }
      });

    this.form.get('description').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(description => {
        this.store.dispatch(updateFormField({ field: 'description', value: description }));
      });

    this.form.get('type').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((type: FormTypeEnum) => {
        this.store.dispatch(updateFormField({ field: 'type', value: type }));

        if (type === FormTypeEnum.InformedConsent) {
          this.form.get('isMandatoryToSign').enable();
        } else {
          this.form.get('isMandatoryToSign').setValue(false);
          this.form.get('isMandatoryToSign').disable();
        }
      });

    this.form.get('version').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(version => {
        this.store.dispatch(updateFormField({ field: 'version', value: version }));
      });

    this.form.get('languageId').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(languageId => {
        this.store.dispatch(updateFormField({ field: 'languageId', value: languageId }));
      });

    this.form.get('studyId').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(studyId => {
        this.store.dispatch(updateFormField({ field: 'studyId', value: studyId }));
      });

    this.form.get('isMandatoryToSign').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(isMandatoryToSign => {
        this.store.dispatch(updateFormField({ field: 'isMandatoryToSign', value: isMandatoryToSign }));
      });
  }

  private minVersionValidator(minVersion: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const currentVersion = control.value;
      if (currentVersion && !this.isVersionGreaterOrEqual(currentVersion, minVersion)) {
        return { minVersion: true };
      }
      return null;
    };
  }

  private isVersionGreaterOrEqual(currentVersion: string, previousVersion: string): boolean {
    const currentParts: number[] = currentVersion.split('.').map(Number);
    const previousParts: number[] = previousVersion.split('.').map(Number);

    for (let i = 0; i < Math.max(currentParts.length, previousParts.length); i++) {
      const current = currentParts[i] || 0;
      const previous = previousParts[i] || 0;

      if (current > previous) {
        return true;
      }
      if (current < previous) {
        return false;
      }
    }
    return true;
  }

}
