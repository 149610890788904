import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { each } from 'lodash-es';

import { AppState, getState } from '../../store/models/app.state';
import { DatasetModel } from '../../dataset/dataset.model';
import { QuestionModel } from '../question.model';
import { resetCurrentQuestion, searchQuestionById, updateQuestionField } from '../store/question.actions';
import { ElementsEnum, FormElementsEnum } from '../../form/form-elements.enum';
import { errorPopup, loading } from '../../store/actions/ui.actions';
import { populateCurrentControl, resetCurrentControl } from '../../store/actions/control.actions';
import { ControlModel } from '../control.model';
import { toggleSidebarSection } from '../../animations';
import { RuleLogicEnum } from '../../rule/rule-logic.enum';
import { RuleModel } from '../../rule/rule.model';
import { QuestionService } from '../question.service';
import { UtilsService } from '../../core/utils.service';
import { AppConfig } from '../../core/config/app.config';


@Component({
  selector: 'phar-question-builder',
  templateUrl: './question-builder.component.html',
  styleUrls: [
    './question-builder.component.scss',
    // '../../_old/_rule/_rule-builder-logic-view/rule-builder-logic-view.component.scss'
  ],
  animations: [toggleSidebarSection]
})
export class QuestionBuilderComponent implements OnInit {
  // @ViewChild('ruleBuilderLogicViewComponent') ruleBuilderLogicViewComponent: RuleBuilderLogicViewComponent;
  name: string;
  selectedTab = 'content';
  currentDataset: DatasetModel;
  question: Observable<QuestionModel>;
  hasPendingChanges$: Observable<boolean>;
  FormElementsEnum = FormElementsEnum;
  rules: {
    show?: RuleModel,
    hide?: RuleModel
  } = {};
  editMode = 'title';
  selectedControl: Observable<ControlModel>;
  elementsIsOpen = 'open';
  controlsIsOpen = 'open';
  openSection = '';
  parentDataField = '';
  formElementsEnum = FormElementsEnum;
  loading$: Observable<boolean>;
  private hasMissingDataFields = false;
  private rulesAreValid = true;

  constructor(
    private store: Store<AppState>,
    private utilsService: UtilsService,
    private appConfig: AppConfig,
    private questionService: QuestionService,
    public dialogRef: MatDialogRef<QuestionBuilderComponent>,
    @Inject(MAT_DIALOG_DATA) public node: any,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(loading(false));
    this.store.dispatch(resetCurrentControl());
    this.currentDataset = getState(this.store).dataset.current.dataset;
    this.question = this.store.select(state => state.question.current.question);
    this.selectedControl = this.store.select(state => state.control.current.control);
    this.hasPendingChanges$ = this.store.select(state => state.question.current.pendingChanges);
    this.loading$ = this.store.select(state => state.ui.loading);

  }

  handleMetaElements(elementType: MetaDataControlTypes, metaElements: IMetaDataControl[]): ControlModel[] {
    const controls: ControlModel[] = [];
    metaElements.forEach((metaElement: IMetaDataControl, index) => {
      const el = {
        controlID: this.generateId() + index,
        question_uuid: this.utilsService.generateUUID(),
        page_uuid: '',
        controlType: '',
        title: '',
        label: '',
        info: '',
        validators: {},
        bindDataField: '',
        dependencies: {},
        inRepeatable: false,
        settings: {},
        grid: {
          columnStart: metaElement.ui.column_index,
          columnEnd: 'span ' + metaElement.ui.column_span,
          rowStart: metaElement.ui.row_index
        }
      }
      if (elementType === MetaDataControlTypes.TEXT_BLOCKS) {
        el.controlType = FormElementsEnum.TextBlock
        el['text'] = metaElement.text;
        controls.push(el);
      }
      if (elementType === MetaDataControlTypes.TEXT_INPUTS) {
        el.controlType = FormElementsEnum.TextInput
        el.label = metaElement.label_details.label_raw;
        controls.push(el);
      }
      if (elementType === MetaDataControlTypes.BOXED_INPUTS) {
        el.controlType = FormElementsEnum.BoxedInput
        el.label = metaElement.label_details.label_raw;
        el.settings['boxes'] = metaElement.settings.size ?? 1;
        controls.push(el);
      }
      if (elementType === MetaDataControlTypes.CHECKBOXES) {
        el.controlType = FormElementsEnum.CheckBox
        el['values'] = [{ label: metaElement.label_details.label_raw, value: '' }]
        controls.push(el);
      }
      if (elementType === MetaDataControlTypes.DATE_PICKERS) {
        el.controlType = FormElementsEnum.DatePicker
        el.label = metaElement.label_details.label_raw;
        el.settings = { customDateFormat: null }
        controls.push(el);
      }
      if (elementType === MetaDataControlTypes.TABLES) {
        el.controlID = this.generateId('DT');
        el.controlType = FormElementsEnum.DataTable
        el.settings = {
          label: '',
          info: '',
          headerBackgroundColor: 'transperant',
          headerTextColor: '#000000',
          borderColor: '#dee2e6',
          columns: [],
          rows: [],
        }
        el['controls'] = []
        el.inRepeatable = false;
        if (metaElement.settings.header_cells.length) {
          metaElement.settings.header_cells.forEach((headerCell, index) => {
            el.settings['columns'].push({
              name: headerCell.text,
              index: headerCell.ui.row_index,
              id: this.generateId('c_')
            })
          })
        } else {
          ///@TODO add case when header ceels arent detected
          for (let i = 1; i <= metaElement.settings.columns; i++) {
            el.settings['columns'].push({
              name: `Cell ${i}`,
              index: i,
              id: this.generateId('c_')
            })
          }
        }
        const rowsTotal = metaElement.settings.header_cells.length ? metaElement.settings.rows - 1 : metaElement.settings.rows;
        for (let i = 1; i <= rowsTotal; i++) {
          el.settings['rows'].push({ id: `r_${i}` })
        }
        controls.push(el);
      }
    })
    return controls;
  }

  // previewImage() {
  //   this.dialog.open(ImagePreviewComponent, {
  //     width: '80vw',
  //     height: '90vh'
  //   })
  // }

  submit(): void {
    if (this.node.mode === 'edit') {
      this.processUpdate();
    } else {
      this.processSubmit();
    }
  }

  processSubmit(): void {
    const controls = getState(this.store).question.current.question.controls;
    this.removeDropZones(controls);
    this.setModel();
    if (this.hasMissingDataFields) {
      this.missingDataFieldsError();
      return;
    }
    if (!this.rulesAreValid) {
      this.invalidRules();
      return;
    }

    let question = getState(this.store).question.current.question;

    if (!question.title) {
      this.store.dispatch(errorPopup({ error: 'Question title is required' }));
      return;
    }
    question = { ...question, datasetId: this.currentDataset.id, formId: this.node.question.formId };
    this.dialogRef.close(question);

  }

  processUpdate(): void {
    const controls = getState(this.store).question.current.question.controls;
    this.removeDropZones(controls);
    this.setModel();
    if (this.hasMissingDataFields) {
      this.missingDataFieldsError('save');
      return;
    }
    if (!this.rulesAreValid) {
      this.invalidRules();
      return;
    }
    const question = getState(this.store).question.current.question;
    this.dialogRef.close(question);
  }

  missingDataFieldsError(type = 'add'): void {
    this.hasMissingDataFields = false;
    this.store.dispatch(errorPopup({
      error: `Please add Data Field to all controls before ${type} question and Repeatable Groups`
    }));
  }

  discardChanges() {
    if (this.node.mode === 'add') {
      this.store.dispatch(resetCurrentQuestion());
    } else {
      this.store.dispatch(resetCurrentControl());
      this.store.dispatch(resetCurrentQuestion());
      this.store.dispatch(searchQuestionById({ id: this.node.question.id }));
      // this.subscription.add(this.question.pipe(skip(1)).pipe(take(1)).subscribe(question => {
      //   if (question.id) {
      //     // this.rules = question.dependencies;
      //   }
      // }));
    }
  }

  close(): void {
    this.store.dispatch(resetCurrentQuestion());
    this.dialogRef.close(false);
  }

  setEditMode(data): void {
    this.editMode = '';
    if (data.control) {
      this.parentDataField = data.parentDataField;
      this.store.dispatch(resetCurrentControl());
      this.store.dispatch(populateCurrentControl({ control: data.control }));
    } else {
      this.store.dispatch(resetCurrentControl());
    }
    setTimeout(() => this.editMode = data.type, 0);
  }

  removeDropZones(rawControls): void {
    const controls = this.questionService.removeDropZonesDeep(rawControls);
    this.store.dispatch(updateQuestionField({ field: 'controls', value: controls }));
  }

  toggleElementsPanel(): void {
    this.elementsIsOpen = this.elementsIsOpen === 'open' ? 'close' : 'open';
  }

  toggleControlsPanel(): void {
    this.controlsIsOpen = this.controlsIsOpen === 'open' ? 'close' : 'open';
  }

  setModel(controls = getState(this.store).question.current.question.controls,
           datasetSchema = getState(this.store).dataset.current.dataset.datasetSchema,
           isDeep = false): any {
    const model = {};
    each(controls, (item) => {
      if (item.controlType in ElementsEnum) {
        return;
      }
      if (item.bindDataField) {
        if (item.controlType === this.formElementsEnum.RepeatableGroup) {
          model[item.bindDataField] = [];
          model[item.bindDataField][0] = this.setModel(item.controls, datasetSchema[item.bindDataField][0], true);
        } else {
          if ((item.controlType === this.formElementsEnum.ImageUpload ||
              item.controlType === this.formElementsEnum.FileUpload) &&
            !item.bindDataField.includes('.' + this.appConfig.config.fileFieldPrefix)
          ) {
            // detect when bindDataField is FILE and append needed fileFieldPrefix to be recognized by the datasetSchema;
            const modifiedBindDataFieldName = this.utilsService.appendFilePrefix(item.bindDataField);
            model[modifiedBindDataFieldName] = datasetSchema[modifiedBindDataFieldName];
          } else {
            model[item.bindDataField] = datasetSchema[item.bindDataField];
          }
        }
      } else {
        if (
          item.controlType === this.formElementsEnum.CheckBox
          || item.controlType === this.formElementsEnum.MultiSelectQuantity
          || item.controlType === this.formElementsEnum.MultiSelect
        ) {
          this.hasMissingDataFields = item.values.some(value => value.value === undefined || value.value === null || value.value === '');
          item.values.forEach((value: { value: string, label: string }) => {
            model[value.value] = datasetSchema[value.value];
          });
        } else if (item.controlType === this.formElementsEnum.DataTable) {
          if (this.filterEmptyDataFields(item.controls).length) {
            this.hasMissingDataFields = true;
          }
        } else {
          this.hasMissingDataFields = true;
        }
      }
    });
    if (isDeep) {
      return model;
    } else {
      this.store.dispatch(updateQuestionField({ field: 'model', value: model }));
      return;
    }
  }

  filterDataFields(ctrls): any[] {
    return ctrls.filter(c => !(c.controlType in ElementsEnum) && (c.bindDataField === undefined || c.bindDataField === null || c.bindDataField === ''))
  }

  filterEmptyDataFields(ctrls: ControlModel[]): any[] {
    let filtered = this.filterDataFields(ctrls);
    filtered = filtered.filter((c: ControlModel) => {
      if (c.controlType === FormElementsEnum.CheckBox) {
        if (!c.values.length) {
          return true;
        } else {

          return Boolean(c.values.filter(e => this.isFieldEmpty(e, 'value')).length);
        }
      } else {
        return true
      }
    })
    return filtered;
  }

  isFieldEmpty(element, field = 'bindDataField'): boolean {
    return (element[field] === undefined || element[field] === null || element[field] === '')
  }

  addRule(logic: string, type: string): void {
    this.rules[type] = {
      logic,
      data: [{
        logic: RuleLogicEnum.Contains,
        value: '',
        model: ''
      }]
    };
  }

  updateRule(rule, type: string): void {
    const dependencies = getState(this.store).question.current.question.dependencies;
    if (rule) {
      dependencies[type] = rule;
    } else {
      delete this.rules[type];
      delete dependencies[type];
    }
    this.store.dispatch(updateQuestionField({ field: 'dependencies', value: dependencies }));
  }

  updateRuleValidity(event): void {
    this.rulesAreValid = event;
  }


  private invalidRules(): void {
    // if (this.ruleBuilderLogicViewComponent) {
    //   // the component is deleted when tab is changed, but the error for invalid fields still persist
    //   this.ruleBuilderLogicViewComponent.touchAllControls();
    // }
    // this.store.dispatch(errorPopup({
    //   error: `You have invalid rules, please check them and fill all required fields.`
    // }));
  }

  private generateId(prefix = 'C'): string {
    return `${prefix}-` + Math.random().toString(36).substr(2, 13);
  }

}

export interface IMetaDataControl {
  text?: string;
  label_details?: { label_raw: string, label_filtered: string, label_coordinates?: number[] };
  ui: IMetaUi,
  settings: {
    size?: number;
    is_required?: boolean;
    multiline?: boolean;
    input_type?: string;
    checkbox_state?: string;
    format?: number[];
    columns?: number;
    rows?: number;
    header_cells?: { ui: IMetaUi, text: string }[],
    cells?: { ui: IMetaUi, elements: [] }

  };
}

export enum MetaDataControlTypes {
  CHECKBOXES = 'checkboxes',
  TEXT_INPUTS = 'text_inputs',
  BOXED_INPUTS = 'boxed_inputs',
  DATE_PICKERS = 'date_pickers',
  TEXT_BLOCKS = 'text_blocks',
  TABLES = 'tables',
  IMAGE_BLOCKS = 'image_blocks'

}

export interface IMetaUi {
  row_index: number;
  column_index: number;
  column_span: number;
  coordinates: number[];
}
