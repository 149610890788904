<div id="rules-container">
  <div id="rules-content" class="d-flex flex-column gap-2">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row rules-btn-row cursor-pointer" matRipple (click)="activateTab(RulesType.Conditions)">
        <mat-icon [svgIcon]="activatedTab === RulesType.Conditions ? 'arrow-right' : 'arrow-down'"></mat-icon>
        <span class="fw-bold ms-2">Conditions
        <phar-help-icon helpText="Conditions allows you to specify how one question or group is contingent on another"
                        position="above"/>
      </span>
        @if (rulesLength$ | async; as conditionsLength) {

          <span class="ms-auto rule-counter">{{ conditionsLength[RulesType.Conditions] }}</span>
        }
      </div>
      @if (activatedTab === RulesType.Conditions) {
        <div class="p-2">
          <phar-conditional-rules-editor/>
        </div>
      }
    </div>
    @if (!rulesDisabled()) {
      <div class="d-flex flex-column">
        <div class="d-flex flex-row rules-btn-row cursor-pointer" matRipple (click)="activateTab(RulesType.EditChecks)">
          <mat-icon [svgIcon]="activatedTab === RulesType.EditChecks ? 'arrow-right' : 'arrow-down'"></mat-icon>
          <span class="fw-bold ms-2">Edit checks
        <phar-help-icon
          helpText="Edit checks allow you to specify acceptable ranges of responses for a question. (Ex. Age cannot be >120"
          position="below"/>
      </span>
          @if (rulesLength$ | async; as conditionsLength) {
            <span class="ms-auto rule-counter">{{ conditionsLength[RulesType.EditChecks] }}</span>
          }
        </div>

        @if (activatedTab === RulesType.EditChecks) {
          <div class="p-2">
            <phar-edit-checks-editor/>
          </div>
        }
      </div>
    }

  </div>
  <div id="rules-actions" class="d-flex align-items-center">
    <button class="me-auto btn-small" mat-raised-button [disabled]="!(hasPendingChanges$ | async)"
            (click)="triggerDiscard()">
      <mat-icon [svgIcon]="'close'"></mat-icon>
      <b>Discard</b>
    </button>

    <button mat-raised-button
            class="ms-auto btn-small button-success"
            (click)="saveGroup()"
            [disabled]="!(hasPendingChanges$ | async)"
    >
      <mat-icon svgIcon="check"></mat-icon>
      <b>Save</b>

    </button>
  </div>
</div>



